import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import useNavigate
import { useDispatch } from "react-redux"; // Import useDispatch
import { logout } from "../../store/auth/authSlice"; // Adjust the import path as necessary

const Header = ({ isActive, onToggle }) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch(); // Initialize useDispatch

  const handleNavControlClick = () => {
    const newIsActive = !isActive;
    onToggle(newIsActive);
  };

  const handleLogout = async () => {
    dispatch(logout());
    localStorage.setItem("prevLocation", "/auth/dashboard");
    navigate("/login");
  };

  const profile = async () => {
    navigate("/auth/profile");
  };

  return (
    <>
      <div className="nav-header">
        <a href="/dashboard" className="brand-logo">
          <img className="logo-abbr" src="/images/logo.png" alt="" />
          <img className="logo-compact" src="/images/logo-text.png" alt="" />
          <img className="brand-title" src="/images/logo-text.png" alt="" />
        </a>
        <div className={`nav-control`} onClick={handleNavControlClick}>
          <div className={`hamburger ${isActive ? "is-active" : ""}`}>
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
      </div>

      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="search_bar dropdown">
                  <span
                    className="search_icon p-3 c-pointer"
                    data-toggle="dropdown"
                  >
                    <i className="mdi mdi-magnify" />
                  </span>
                  <div className="dropdown-menu p-0 m-0">
                    <form>
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav header-right">
                <li className="nav-item dropdown notification_dropdown">
                  <a
                    className="nav-link"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <i className="mdi mdi-bell" />
                    <div className="pulse-css" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <ul className="list-unstyled">
                      <li className="media dropdown-item">
                        <span className="success">
                          <i className="ti-user" />
                        </span>
                        <div className="media-body">
                          <a href="#">
                            <p>
                              <strong>Martin</strong> has added a{" "}
                              <strong>customer</strong> Successfully
                            </p>
                          </a>
                        </div>
                        <span className="notify-time">3:20 am</span>
                      </li>
                    </ul>
                    <a className="all-notification" href="#">
                      See all notifications <i className="ti-arrow-right" />
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown header-profile">
                  <a
                    className="nav-link"
                    href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <i className="mdi mdi-account" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-item hand-cursor" onClick={profile}>
                      <i className="fa-solid fa-user" />
                      <span className="ml-2">Profile </span>
                    </div>

                    <div className="dropdown-item hand-cursor" onClick={handleLogout}>
                      <i className="fa fa-sign-out" />
                      <span className="ml-2">Logout </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
