import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import axios from "../axiosConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  TableSortLabel,
  Grid,
} from "@material-ui/core";
import ReactModal from "react-modal";
import { makeStyles } from '@material-ui/core/styles';

ReactModal.setAppElement("#root");

const useStyles = makeStyles({
  tableCellCenter: {
    textAlign: 'center',
  },
  createdDate: {
    width: '200px', 
    minWidth: '200px', 
  },
  keyColumn: {
    width: '200px',
  },
  productColumn: {
    width: '200px',
  },
  licenseColumn: {
    width: '150px',
  },
  issuedToColumn: {
    width: '150px',
  },
  orderIdColumn: {
    width: '120px',
    minWidth: '120px',
  },
  statusColumn: {
    width: '120px',
  },
  registeredDateColumn: {
    width: '150px',
  },
  lastUsedColumn: {
    width: '150px',
    minWidth:'150px',
  },
  actionsColumn: {
    width: '100px',
  },
});

const KeyDetails = () => {
  const classes = useStyles();

  const [keysData, setKeysData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const getData = async () => {
    try {
      const response = await axios.get("/api/activation/keys", {
        withCredentials: true,
      });
      setKeysData(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
      setKeysData([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formatDate = (dateString) => {
    const createdAt = new Date(dateString);
    const hours = createdAt.getHours() % 12 || 12; 
    const amPm = createdAt.getHours() >= 12 ? "PM" : "AM";
    return `${createdAt.getDate()}/${
      createdAt.getMonth() + 1
    }/${createdAt.getFullYear()} ${hours}:${createdAt
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${amPm}`;
  };

  const statusText = (currentStatus) => {
    return currentStatus ? "Blocked" : "Active";
  };

  const handleSort = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  const filteredData = keysData.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedData = filteredData.slice().sort((a, b) => {
    const aValue = a[sortedField];
    const bValue = b[sortedField];
    if (aValue < bValue) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Key Details | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="pb-2">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={9} md={9}>
                          <h4 className="card-title">Key Details</h4>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <TextField
                            label="Search..."
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            size="small"
                            fullWidth
                            InputProps={{
                              style: {
                                borderRadius: 0,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      <TableContainer
                        component={Paper}
                        style={{ overflowX: "auto" }}
                      >
                        <Table className="table table-bordered table-striped verticle-middle table-responsive-sm">
                          <TableHead className="thead-primary">
                            <TableRow>
                              <TableCell className={`${classes.tableCellCenter} ${classes.createdDate}`}>
                                <TableSortLabel
                                  active={sortedField === "Created Date"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Created Date")}
                                >
                                  Created Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.keyColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "Key"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Key")}
                                >
                                  Key
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.productColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "Product"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Product")}
                                >
                                  Product
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.licenseColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "License"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("License")}
                                >
                                  License
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.issuedToColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "Issued to"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Issued to")}
                                >
                                  Issued to
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.orderIdColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "Order Id"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Order Id")}
                                >
                                  Order Id
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.statusColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "Status"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Status")}
                                >
                                  Status
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.registeredDateColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "Registered Date"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Registered Date")}
                                >
                                  Registered Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={`${classes.tableCellCenter} ${classes.lastUsedColumn}`}>
                                <TableSortLabel
                                  active={sortedField === "Last Used"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("Last Used")}
                                >
                                  Last Used
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={classes.tableCellCenter}>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedData
                              .slice(
                                page * pageSize,
                                page * pageSize + pageSize
                              )
                              .map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell className={classes.tableCellCenter}>
                                    {formatDate(row["createdAt"])}
                                  </TableCell>
                                  <TableCell className={classes.tableCellCenter}>{row["key"]}</TableCell>
                                  <TableCell className={classes.tableCellCenter}>{row["product"]}</TableCell>
                                  <TableCell className={classes.tableCellCenter}>{row["license"]}</TableCell>
                                  <TableCell className={classes.tableCellCenter}>{row["issuedEmail"]}</TableCell>
                                  <TableCell className={classes.tableCellCenter}>{row["orderId"]}</TableCell>
                                  <TableCell className={classes.tableCellCenter}>
                                    {statusText(row["isBlocked"])}
                                  </TableCell>
                                  <TableCell className={classes.tableCellCenter}>{row["activationDate"]}</TableCell>
                                  <TableCell className={classes.tableCellCenter}>
                                    {formatDate(row["updatedAt"])}
                                  </TableCell>
                                  <TableCell className={classes.tableCellCenter}>Actions</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      count={filteredData.length}
                      rowsPerPage={pageSize}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default KeyDetails;
