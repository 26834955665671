import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import axios from "../axiosConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TextField,
  TablePagination,
  Grid,
  TableSortLabel,
} from "@material-ui/core";

import ReactModal from "react-modal";
ReactModal.setAppElement("#root");

const Products = () => {
 
  const [data, setData] = useState([]);
  const [license, setLicense] = useState([
    {
      name: "",
      price: [
        {
          currency: "",
          base: 0,
          purchase: 0,
          renewal: 0,
          isRenewalMndatory: true,
        },
      ],
      features: [
        {
          title: "",
          limit: "",
        },
      ],
    },
  ]);

  const [features, setFeatures] = useState();

  const getData = async () => {
    try {
      const response = await axios.get("/api/product/all", {
        withCredentials: true,
      });
      //console.log(response.data);
      setData(response.data);
    } catch (error) {
      setData([]);
      setLicense([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const closeError = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

 
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const filteredData = data.filter((item) =>
    ["name"].some((key) => {
      const value = item[key];
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );
  const handleSort = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };
  const sortedData = filteredData.slice().sort((a, b) => {
    const aValue = a[sortedField];
    const bValue = b[sortedField];
    if (aValue < bValue) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

 

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [action, setAction] = useState("");
  const [uid, setUid] = useState({});

  const openEditModal = async (e) => {
    setAction(e.currentTarget.getAttribute("action"));
    setUid(JSON.parse(e.currentTarget.getAttribute("uid")));
    const product = JSON.parse(e.currentTarget.getAttribute("uid"));
    setLicense(product.license);
    setFeatures(product.license[0].features);
    //console.log("product.license.features", product.license[0].features);
    closeError();
    openModal();
  };

  

  const [selectedItems, setSelectedItems] = useState([]);
  const [newLicense, setNewLicense] = useState("");

  // Function to handle checkbox toggle
  const handleCheckboxChange = (value) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  const handleDeleteSelectedLicense = () => {
    // Remove selected licenses from the license list
    const updatedLicense = license.filter(
      (item) => !selectedItems.includes(item.name)
    );
    setLicense(updatedLicense);
    // Clear selected items
    setSelectedItems([]);
  };

  const handleAddLicense = () => {
    if (newLicense.trim() !== "") {
      if (!license.some((item) => item.name === newLicense.trim())) {
        setLicense((prevLicense) => [
          ...prevLicense,
          { name: newLicense.trim() },
        ]);
        setSuccessMessage("License added successfully.");
      } else {
        setErrorMessage("This license already exists.");
      }
      setNewLicense("");
    } else {
      setErrorMessage("Cannot add an invalid license.");
    }
  };

  const handleSaveLicense = async () => {
    //console.log("Final", license);
    const name = uid.name;
    try {
      const response = await axios.post(
        "/api/product/license",
        { name, license },
        {
          withCredentials: true,
        }
      );
      console.log(response.data);
      setSuccessMessage("License added successfully.");
    } catch (error) {
      console.error(error);
      setErrorMessage("Unable to set license");
    }

    getData();
  };

  //------------------------------------------------------------ Edit Features

  const [editedFeatures, setEditedFeatures] = useState({});
  const [selectedLicense, setSelectedLicense] = useState(null);

  // Function to initialize edited features for each license
  const initializeEditedFeatures = () => {
    const initialEditedFeatures = {};
    license.forEach((item) => {
      initialEditedFeatures[item.name] = [...item.features];
    });
    setEditedFeatures(initialEditedFeatures);
  };

  useEffect(() => {
    if (license.length > 0) {
      initializeEditedFeatures();
    }
  }, [license]);

  // Function to handle editing existing features
  const handleFeatureEdit = (licenseName, index, newValue) => {
    const updatedFeatures = { ...editedFeatures };
    updatedFeatures[licenseName][index] = newValue;
    setEditedFeatures(updatedFeatures);
  };

  // Function to handle adding new features
  const handleAddFeature = (licenseName) => {
    const updatedFeatures = { ...editedFeatures };
    updatedFeatures[licenseName] = [
      ...updatedFeatures[licenseName],
      { key: "", value: "" },
    ];
    setEditedFeatures(updatedFeatures);
  };

  // Function to handle saving the edited features
  const handleSaveFeatures = async () => {
    try {
      const updatedLicense = license.map((item) => {
        const features = editedFeatures[item.name] || [];
        if (item.name === selectedLicense) {
          return { ...item, features };
        }
        return item;
      });
      setLicense(updatedLicense);

      const name = uid.name;
      try {
        //console.log("updated license", updatedLicense);
        const response = await axios.post(
          "/api/product/license",
          { name, license: updatedLicense },
          {
            withCredentials: true,
          }
        );
        console.log(response.data);
        setSuccessMessage("Features updated successfully.");
      } catch (error) {
        console.error(error);
        setErrorMessage("Unable to update features");
      }

      // Additional code to send the updated features to the backend
    } catch (error) {
      console.error(error);
      setErrorMessage("Unable to update features");
    }
    getData();
  };

  //------------------------------------------------------------- Edit pricing
  const [selectedLicenseForPricing, setSelectedLicenseForPricing] =
    useState(null);
  const [editedPricing, setEditedPricing] = useState({});
  const [newPricing, setNewPricing] = useState({
    currency: "",
    base: 0,
    purchase: 0,
    renewal: 0,
    isRenewalMandatory: true,
  });

  // Function to initialize edited pricing for each license
  const initializeEditedPricing = () => {
    const initialEditedPricing = {};
    license.forEach((item) => {
      initialEditedPricing[item.name] = [...item.price];
    });
    setEditedPricing(initialEditedPricing);
  };

  useEffect(() => {
    if (license.length > 0) {
      initializeEditedPricing();
    }
  }, [license]);

  // Function to handle editing pricing details
  const handlePriceEdit = (licenseName, currency, field, value) => {
    const updatedPricing = { ...editedPricing };
    const licensePricing = updatedPricing[licenseName];
    const updatedCurrencyIndex = licensePricing.findIndex(
      (price) => price.currency === currency
    );
    if (updatedCurrencyIndex !== -1) {
      updatedPricing[licenseName][updatedCurrencyIndex] = {
        ...licensePricing[updatedCurrencyIndex],
        [field]: value,
      };
      setEditedPricing(updatedPricing);
    }
  };

  // Function to add new pricing details for a new currency
  // Function to add new pricing details for a new currency
  // Function to add new pricing details for a new currency
  const handleAddPriceSet = (licenseName) => {
    console.log("Adding new price set...");
    const updatedPricing = { ...editedPricing };
    updatedPricing[licenseName] = [
      ...updatedPricing[licenseName],
      {
        currency: "",
        base: 0,
        purchase: 0,
        renewal: 0,
        isRenewalMandatory: true,
      },
    ];
    console.log("Updated pricing:", updatedPricing);
    setEditedPricing(updatedPricing);
  };

  // Function to save the changes made to pricing details
  const handleSavePricing = async () => {
    try {
      const updatedLicense = license.map((item) => {
        if (item.name === selectedLicenseForPricing) {
          return {
            ...item,
            price: editedPricing[item.name],
          };
        }
        return item;
      });
      setLicense(updatedLicense);
      const name = uid.name;
      try {
        //console.log("updated license", updatedLicense);
        const response = await axios.post(
          "/api/product/license",
          { name, license: updatedLicense },
          {
            withCredentials: true,
          }
        );
        console.log(response.data);
        setSuccessMessage("Price updated successfully.");
      } catch (error) {
        console.error(error);
        setErrorMessage("Unable to update price");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Unable to update pricing details.");
    }
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />

        <title>Product Details | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="pb-2">
                      <Grid container spacing={2} >
                      <Grid item xs={12} sm={9} md={9}>
                          <h4 className="card-title">Product Details</h4>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <TextField className="raja"
                            label="Search..."

                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            size="small"
                            fullWidth

                            InputProps={{
                              style: {
                                borderRadius: 0, 
                              },
                            }}
                      
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <TableContainer component={Paper}>
                      <Table
                        className="table table-bordered table-striped verticle-middle table-responsive-sm"
                        id="ApilogTable"
                      >
                        <TableHead className="thead-primary">
                          <TableRow>
                            <TableCell>
                              <TableSortLabel
                                active={sortedField === "name"}
                                direction={sortDirection}
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </TableSortLabel>
                            </TableCell>
                            <TableCell>License Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedData
                            .slice(page * pageSize, page * pageSize + pageSize)
                            .map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                  <span>
                                    <div className="row">
                                      <div
                                        onClick={openEditModal}
                                        action="editLicense"
                                        uid={JSON.stringify(row)}
                                        className="ml-2 mr-3 hand-cursor div-hover"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit License"
                                      >
                                        <i
                                          class="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                      <div
                                        onClick={openEditModal}
                                        action="editFeatures"
                                        uid={JSON.stringify(row)}
                                        className="mr-3 hand-cursor div-hover"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit License Fatures"
                                      >
                                        <i className="fas fa-key color-muted" />
                                      </div>
                                      <div
                                        onClick={openEditModal}
                                        action="editPricing"
                                        uid={JSON.stringify(row)}
                                        className="mr-3 hand-cursor div-hover"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit Pricing"
                                      >
                                        <i
                                          class="fa fa-money"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />

                  {/* Items count */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
      ariaHideApp={true}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 1000,
        },
        content: {
          width: "90%",
          maxWidth: "600px",
          height: "90%",
          maxHeight: "500px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1001,
          position: "relative",
          padding: "20px",
        },
      }}
      contentLabel="Example Modal"
    >
      <button
        onClick={closeModal}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          border: "none",
          background: "transparent",
          fontSize: "30px",
          cursor: "pointer",
          zIndex: "1000",
        }}
      >
        &times;
      </button>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {action === "editLicense" && (
              <div className="row mt-4">
                <div className="col-12">
                  <p>
                    <strong>Name:</strong> {uid.name}
                  </p>
                </div>
                <div
                  className="col-12"
                  style={{
                    maxHeight: "250px",
                    overflowY: "auto",
                    paddingTop: "7px",
                    border: "1px solid #ccc",
                    marginBottom: "20px",
                  }}
                >
                  {license &&
                    license.map((item, index) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={`checkbox-${index}`}
                          checked={selectedItems.includes(item.name)}
                          onChange={() => handleCheckboxChange(item.name)}
                        />
                        <label className="ml-2" htmlFor={`checkbox-${index}`}>
                          {item.name}
                        </label>
                      </div>
                    ))}
                </div>
                <div className="col-12 mb-3">
                  <button
                    className="btn btn-danger mr-2"
                    onClick={handleDeleteSelectedLicense}
                  >
                    <i className="fas fa-trash-alt color-danger" /> Delete
                    Selected
                  </button>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-8">
                      <input
                        list="accessOptions"
                        onFocus={closeError}
                        className="form-control"
                        placeholder="Search or select an existing item..."
                        value={newLicense}
                        onChange={(e) => setNewLicense(e.target.value)}
                      />
                      <datalist id="accessOptions">
                        {license &&
                          license.map((item, index) => (
                            <option key={index} value={item.name} />
                          ))}
                      </datalist>
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddLicense}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-success"
                    onClick={handleSaveLicense}
                  >
                    <i className="fas fa-save mr-2"></i>
                    Save License
                  </button>
                </div>
                <div className="col-12 text-center mt-4">
                  {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                  )}
                  {successMessage && (
                    <p className="text-success">{successMessage}</p>
                  )}
                </div>
              </div>
            )}
            {action === "editFeatures" && (
              <div className="row mt-4">
                <div className="col-12">
                  <p>
                    <strong>Name:</strong> {uid.name}
                  </p>
                </div>
                <div className="col-12">
                  <Select
                    value={selectedLicense}
                    onChange={(e) => {
                      setSelectedLicense(e.target.value);
                    }}
                    fullWidth
                  >
                    {license.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  className="col-12 mt-4"
                  style={{
                    minHeight: "240px",
                    maxHeight: "240px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    padding: "10px",
                  }}
                >
                  {selectedLicense &&
                    editedFeatures[selectedLicense]?.map((feature, index) => (
                      <div key={index} className="row mt-2">
                        <div className="col-6">
                          <TextField
                            label="Feature Key"
                            variant="outlined"
                            value={feature.key}
                            onChange={(e) =>
                              handleFeatureEdit(selectedLicense, index, {
                                ...feature,
                                key: e.target.value,
                              })
                            }
                            fullWidth
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            label="Feature Value"
                            variant="outlined"
                            value={feature.value}
                            onChange={(e) =>
                              handleFeatureEdit(selectedLicense, index, {
                                ...feature,
                                value: e.target.value,
                              })
                            }
                            fullWidth
                          />
                        </div>
                      </div>
                    ))}
                </div>
                {selectedLicense && (
                  <div className="col-12 mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddFeature(selectedLicense)}
                        >
                          Add Feature
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn-success"
                          onClick={handleSaveFeatures}
                        >
                          <i className="fas fa-save mr-2"></i>
                          Save Features
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12 text-center mt-4">
                  {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                  )}
                  {successMessage && (
                    <p className="text-success">{successMessage}</p>
                  )}
                </div>
              </div>
            )}
            {action === "editPricing" && (
              <div className="row mt-4">
                <div className="col-12">
                  <p><strong>Name:</strong> {uid.name}</p>
                </div>
                <div className="col-10">
                  <Select
                    value={selectedLicenseForPricing}
                    onChange={(e) =>
                      setSelectedLicenseForPricing(e.target.value)
                    }
                    fullWidth
                  >
                    {license.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {selectedLicenseForPricing && (
                  <>
                    <div className="col-12 mt-2">
                      {editedPricing[selectedLicenseForPricing].map(
                        (priceSet, index) => (
                          <div key={index} className="row m-0 p-0 mt-2">
                            <div className="col-2 m-0 p-0">
                              <TextField
                                label="Currency"
                                variant="outlined"
                                value={priceSet.currency}
                                onChange={(e) =>
                                  handlePriceEdit(
                                    selectedLicenseForPricing,
                                    priceSet.currency,
                                    "currency",
                                    e.target.value
                                  )
                                }
                                fullWidth
                              />
                            </div>
                            <div className="col-2 m-0 p-0">
                              <TextField
                                label="Base"
                                variant="outlined"
                                value={priceSet.base}
                                onChange={(e) =>
                                  handlePriceEdit(
                                    selectedLicenseForPricing,
                                    priceSet.currency,
                                    "base",
                                    e.target.value
                                  )
                                }
                                fullWidth
                              />
                            </div>
                            <div className="col-2 m-0 p-0">
                              <TextField
                                label="Purchase"
                                variant="outlined"
                                value={priceSet.purchase}
                                onChange={(e) =>
                                  handlePriceEdit(
                                    selectedLicenseForPricing,
                                    priceSet.currency,
                                    "purchase",
                                    e.target.value
                                  )
                                }
                                fullWidth
                              />
                            </div>
                            <div className="col-2 m-0 p-0">
                              <TextField
                                label="Renewal"
                                variant="outlined"
                                value={priceSet.renewal}
                                onChange={(e) =>
                                  handlePriceEdit(
                                    selectedLicenseForPricing,
                                    priceSet.currency,
                                    "renewal",
                                    e.target.value
                                  )
                                }
                                fullWidth
                              />
                            </div>
                            <div className="col-2 m-0 p-0">
                              <Select
                                variant="outlined"
                                value={priceSet.isRenewalMandatory}
                                onChange={(e) =>
                                  handlePriceEdit(
                                    selectedLicenseForPricing,
                                    priceSet.currency,
                                    "isRenewalMandatory",
                                    e.target.value
                                  )
                                }
                                fullWidth
                              >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </Select>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    
                    <div className="col-6 mt-3">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          handleAddPriceSet(selectedLicenseForPricing)
                        }
                      >
                        Add Price Set
                      </button>
                    </div>
                    <div className="col-6 mt-3">
                      <button
                        className="btn btn-success"
                        onClick={handleSavePricing}
                      >
                        <i className="fas fa-save mr-2"></i>
                        Save Pricing
                      </button>
                    </div>
                    <div className="col-12 text-center mt-4">
                      {errorMessage && (
                        <p className="text-danger">{errorMessage}</p>
                      )}
                      {successMessage && (
                        <p className="text-success">{successMessage}</p>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ReactModal>
    </Layout>
  );
};

export default Products;
