import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


const NotFound = () => {

  const history = JSON.parse(localStorage.getItem("navHistory"))

  useEffect(() => {

    localStorage.setItem("prevLocation", history[history.length - 2]);

  }, [history]);

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        

        <title>
          404 | CodeAchi Technologies - Your Software Solutions Partner
        </title>
      </Helmet>
      <div className="container-fluid bg-primary py-2 bg-header">
        <div className="row ly-5">
          <div className="col-12 text-center">
            <h1
              className="text-white text-uppercase mb-3 animated slideInDown"
              style={{ marginTop: 90 }}
            >
              404 - Not found
            </h1>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6">
            <img
              src="images/your-404-image.png"
              alt="404 Error Image"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
            <p className="display-5">404-  Not Found - got : </p>
            <p className="lead">
              We're sorry, but the page you are looking for might have been
              removed or does not exist.
            </p>
            <div
              className="container bg-light"
              style={{
                boxShadow: "rgb(221, 221, 221) 0px 0px 30px",
                animationDelay: "0.1s",
                animationName: "none",
              }}
              data-wow-delay="0.1s"
            >
              <div className="row" style={{ padding: 12 }}>
                <div className="col-lg-8 ">
                  <div className="text-center position-relative mt-3 mx-auto">
                    <p className="fw-bold text-primary nf-2 text-uppercase">
                      No where to go?
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <Link
                    to="/"
                    className="btn btn-primary py-md-2 px-md-6 me-3 wow animated slideInLeft"
                  >
                    back to home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
