import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Grid,
  TableSortLabel,
} from "@material-ui/core";
import ReactModal from "react-modal";
import axios from "../axiosConfig";
import RegistrationDetails from "../components/product/RegistrationDetails";
import UsageDetails from "../components/product/UsageDetails";
import BlockMachine from "../components/product/BlockMachine";

ReactModal.setAppElement("#root");

const ProductRegistrationList = () => {
  const [registrations, setRegistrations] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState({});
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/product/registrations", {
          withCredentials: true,
        });
        setRegistrations(response.data);
      } catch (error) {
        console.error("Error fetching product registrations:", error);
        setRegistrations([]);
      }
    };

    fetchData();
  }, []);

  const filteredData = registrations.filter((registration) =>
    [
      "machineId",
      "client.name",
      "client.designation",
      "client.email",
      "client.phone",
      "organization.name",
      "organization.website",
      "organization.address",
      "organization.country",
      "product.name",
      "lastUsed",
    ].some((key) => {
      let value = registration;
      for (let property of key.split(".")) {
        value = value[property];
      }
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  const handleSort = (field) => {
    const isAsc = sortedField === field && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortedField(field);
  };

  const getSortValue = (registration, field) => {
    if (field) {
      const fields = field.split(".");
      let value = registration;
      for (let property of fields) {
        value = value ? value[property] : null; 
      }
      return value instanceof Date ? value.getTime() : value;
    }
  };

  const sortedData = filteredData.sort((a, b) => {
    const aValue = getSortValue(a, sortedField);
    const bValue = getSortValue(b, sortedField);

    if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
    if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); 
    const yy = date.getFullYear().toString().substr(-2);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? String(hours).padStart(2, "0") : "12";

    return `${dd}/${mm}/${yy} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const [action, setAction] = useState("");
  const [machineId, setMachineId] = useState({});
  const [randomNumber, setRandomNumber] = useState(0);
  const openEditModal = async (e) => {
    setAction(e.currentTarget.getAttribute("action"));
    setMachineId(
      JSON.parse(e.currentTarget.getAttribute("register")).machineId
    );
    const newRandomNumber = Math.floor(Math.random() * 101);
    setRandomNumber(newRandomNumber);
  };

  useEffect(() => {
    if (action && machineId) {
      openModal();
    }
  }, [randomNumber]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Product Registrations | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="">
                    <div className="pb-2">
                      <Grid container spacing={2}>
                      <Grid item xs={12} sm={9} md={9}>
                          <h4 className="card-title">Product Registrations</h4>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <TextField
                            label="Search..."
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            size="small"
                            fullWidth

                            
                            InputProps={{
                              style: {
                                borderRadius: 0, 
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    {registrations.length > 0 ? (
                      <>
                        <TableContainer component={Paper}>
                          <Table className="table table-bordered table-striped">
                            <TableHead className="thead-primary">
                              <TableRow>
                                <TableCell title="Install date"   style={{ textAlign: 'center' }}>
                                  <TableSortLabel
                                    active={sortedField === "createdAt"}
                                    direction={sortDirection}
                                    onClick={() => handleSort("createdAt")}
                                    
                                  >
                                    Date
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell title="machine ID"  style={{ textAlign: 'center' }}>
                                  <TableSortLabel
                                    active={sortedField === "machineId"}
                                    direction={sortDirection}
                                    onClick={() => handleSort("machineId")}
                                  >
                                    ID
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell title="client details"  style={{ textAlign: 'center' }}>
                                  <TableSortLabel
                                    active={sortedField === "client.name"}
                                    direction={sortDirection}
                                    onClick={() => handleSort("client.name")}
                                  >
                                    Client
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell title="organization details" style={{ textAlign: 'center' }}>
                                  <TableSortLabel
                                    active={sortedField === "organization.name"}
                                    direction={sortDirection}
                                    onClick={() =>
                                      handleSort("organization.name")
                                    }
                                  >
                                    Organization
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  <TableSortLabel
                                    active={sortedField === "product.name"}
                                    direction={sortDirection}
                                    onClick={() => handleSort("product.name")}
                                  >
                                    Product
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell title="last used" style={{ textAlign: 'center' }}>
                                  <TableSortLabel
                                    active={sortedField === "lastUsed"}
                                    direction={sortDirection}
                                    onClick={() => handleSort("lastUsed")}
                                  >
                                    Latest
                                  </TableSortLabel>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sortedData
                                .slice(
                                  page * pageSize,
                                  page * pageSize + pageSize
                                )
                                .map((registration, index) => (
                                  <TableRow key={index}>
                                    <TableCell style={{ fontSize: "12px",  textAlign: 'center' }}>
                                      {formatDate(registration.createdAt)}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "12px",  textAlign: 'center' }}>
                                      {registration.machineId}
                                    </TableCell>
                                    <TableCell>
                                      <div className="row">
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          {registration.client.name}
                                        </div>
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          <span className="badge badge-primary">
                                            {registration.client.designation}
                                          </span>
                                        </div>
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          {registration.client.email}
                                        </div>
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          {registration.client.phone}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="row">
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          {registration.organization.name}
                                        </div>
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          {registration.organization.website}
                                        </div>
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          {registration.organization.address}
                                        </div>
                                        <div
                                          className="col-md-12"
                                          style={{ fontSize: "12px",  textAlign: 'center' }}
                                        >
                                          {registration.organization.country}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ fontSize: "12px",  textAlign: 'center' }}>
                                      {registration.product.name}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "12px",  textAlign: 'center' }}>
                                      {formatDate(registration.lastUsed)}
                                    </TableCell>
                                    <TableCell>
                                      <div className="row text-center">
                                        <div className="col-md-12">
                                          <div
                                            onClick={openEditModal}
                                            action="viewDtails"
                                            register={JSON.stringify(
                                              registration
                                            )}
                                            className="mr-3 hand-cursor div-hover"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="View Details"
                                          >
                                            <i className="fas fa-eye color-muted" />
                                          </div>
                                          <div
                                            onClick={openEditModal}
                                            action="trialUsage"
                                            register={JSON.stringify(
                                              registration
                                            )}
                                            className="mt-2 mr-3 hand-cursor div-hover"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="View/edit Usage"
                                          >
                                            <i className="fas fa-chart-line color-muted" />
                                          </div>
                                          <div
                                            onClick={openEditModal}
                                            action="blockMachine"
                                            register={JSON.stringify(
                                              registration
                                            )}
                                            className="mt-2 mr-3 hand-cursor div-hover"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Block Machine ID"
                                          >
                                            <i className="fas fa-ban color-muted " />
                                          </div>
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <TablePagination
                          rowsPerPageOptions={[25, 50, 100, 500]}
                          component="div"
                          count={filteredData.length}
                          rowsPerPage={pageSize}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </>
                    ) : (
                      <div>Loading...</div> 
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 1000,
          },
          content: {
            width: "90%",
            height: "80%",
            maxWidth: "600px",
            maxHeight: "90%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
            padding: "20px",
            overflow: "auto",
          },
        }}
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            border: "none",
            background: "transparent",
            fontSize: "30px",
            cursor: "pointer",
            zIndex: "1000",
          }}
        >
          &times;
        </button>
        {action === "viewDtails" && (
          <RegistrationDetails machineId={machineId} />
        )}
        {action === "trialUsage" && <UsageDetails machineId={machineId} />}
        {action === "blockMachine" && <BlockMachine machineId={machineId} />}
      </ReactModal>
    </Layout>
  );
};

export default ProductRegistrationList;
