import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Grid,
  TableSortLabel,
  Button,
  IconButton,
} from "@material-ui/core";
import ReactModal from "react-modal";
import axios from "../axiosConfig";
import { makeStyles } from "@material-ui/core/styles";

ReactModal.setAppElement("#root");

const useStyles = makeStyles({
  tableCellCenter: {
    textAlign: "center",
  },
  createdDate: {
    width: "200px",
    minWidth: "200px",
  },
  keyColumn: {
    width: "200px",
  },
  productColumn: {
    width: "200px",
  },
  statusColumn: {
    width: "120px",
  },
  lastUsedColumn: {
    width: "150px",
    minWidth: "150px",
  },
  installLimitColumn: {
    width: "100px",
  },
  actionsColumn: {
    width: "150px",
  },
});

const KeyDetails = () => {
  const classes = useStyles();

  const [keysData, setKeysData] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [installedMachines, setInstalledMachines] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);
  const [action, setAction] = useState("");

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const getData = async () => {
    try {
      const response = await axios.get("/api/systemstraders/keys", {
        withCredentials: true,
      });
      setKeysData(response.data);
    } catch (error) {
      console.log(error);
      setKeysData([]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours() % 12 || 12;
    const amPm = date.getHours() >= 12 ? "PM" : "AM";
    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${hours}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${amPm}`;
  };

  const statusText = (currentStatus) => {
    return currentStatus ? "Blocked" : "Active";
  };

  const handleSort = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  const toggleKeyStatus = async (id) => {
    try {
      await axios.post(`/api/systemstraders/keys/${id}/toggle-status`);
      getData(); // Refresh the data
    } catch (error) {
      console.error("Error toggling key status:", error);
    }
  };

  const viewInstalledMachines = async (key) => {
    setSelectedKey(key);
    console.log("key : ", key);
    setAction("viewDetails");
    try {
      const response = await axios.get(
        `/api/systemstraders/keys/${key._id}/machines`,
        {
          withCredentials: true,
        }
      );
      setInstalledMachines(response.data);
      console.log(response.data);
      openModal(); // Ensure this is called after setInstalledMachines
    } catch (error) {
      console.error("Error fetching installed machines:", error);
    }
  };

  const deleteMachine = async (keyId, hardwareId) => {
    try {
      await axios.delete(
        `/api/systemstraders/keys/${keyId}/machines/${hardwareId}`
      );
      getData(); // Refresh the data
    } catch (error) {
      console.error("Error deleting machine:", error);
    }
  };

  const filteredData = keysData.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedData = filteredData.slice().sort((a, b) => {
    const aValue = a[sortedField];
    const bValue = b[sortedField];
    if (aValue < bValue) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Key Details | SystemTraders</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="pb-2">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={9} md={9}>
                          <h4 className="card-title">Key Details</h4>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <TextField
                            label="Search..."
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            size="small"
                            fullWidth
                            InputProps={{
                              style: {
                                borderRadius: 0,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      <TableContainer
                        component={Paper}
                        style={{ overflowX: "auto" }}
                      >
                        <Table className="table table-bordered table-striped verticle-middle table-responsive-sm">
                          <TableHead className="thead-primary">
                            <TableRow>
                              <TableCell
                                className={`${classes.tableCellCenter} ${classes.createdDate}`}
                              >
                                <TableSortLabel
                                  active={sortedField === "createdAt"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("createdAt")}
                                >
                                  Created Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellCenter} ${classes.keyColumn}`}
                              >
                                <TableSortLabel
                                  active={sortedField === "key"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("key")}
                                >
                                  Key
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellCenter} ${classes.productColumn}`}
                              >
                                <TableSortLabel
                                  active={sortedField === "product"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("product")}
                                >
                                  Product
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellCenter} ${classes.installLimitColumn}`}
                              >
                                Install Limit
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellCenter} ${classes.statusColumn}`}
                              >
                                <TableSortLabel
                                  active={sortedField === "isBlocked"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("isBlocked")}
                                >
                                  Status
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className={`${classes.tableCellCenter} ${classes.lastUsedColumn}`}
                              >
                                <TableSortLabel
                                  active={sortedField === "lastUsed"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("lastUsed")}
                                >
                                  Last Used
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className={classes.tableCellCenter}>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedData
                              .slice(
                                page * pageSize,
                                page * pageSize + pageSize
                              )
                              .map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    className={classes.tableCellCenter}
                                  >
                                    {formatDate(row["createdAt"])}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellCenter}
                                  >
                                    {row["key"]}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellCenter}
                                  >
                                    {row["product"]} {row["_id"]}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellCenter}
                                  >
                                    {row["installLimit"]}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellCenter}
                                  >
                                    {statusText(row["isBlocked"])}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellCenter}
                                  >
                                    {formatDate(row["lastUsed"])}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableCellCenter}
                                  >
                                    <Button
                                      onClick={() => toggleKeyStatus(row._id)}
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                    >
                                      {row.isBlocked ? "Unblock" : "Block"}
                                    </Button>
                                    <IconButton
                                      onClick={() => viewInstalledMachines(row)}
                                      color="primary"
                                      size="small"
                                    >
                                      <i className="fas fa-eye color-muted" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      count={filteredData.length}
                      rowsPerPage={pageSize}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for viewing installed machines */}
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 1000,
          },
          content: {
            width: "90%",
            height: "80%",
            maxWidth: "600px",
            maxHeight: "90%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
            padding: "20px",
            overflow: "auto",
          },
        }}
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            border: "none",
            background: "transparent",
            fontSize: "30px",
            cursor: "pointer",
            zIndex: "1000",
          }}
        >
          &times;
        </button>
        {action === "viewDetails" && (
          <div>
            <h3>Installed Machines</h3>
            {installedMachines && installedMachines.length > 0 ? (
              installedMachines.map((machine, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <p>Machine ID: {machine.hardwareId}</p>
                  <p>Registered At: {formatDate(machine.registerdAt)}</p>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      deleteMachine(selectedKey?._id, machine.hardwareId)
                    }
                  >
                    Delete Machine
                  </Button>
                  <hr />
                </div>
              ))
            ) : (
              <p>No machines registered with this key.</p>
            )}
          </div>
        )}
      </ReactModal>
    </Layout>
  );
};

export default KeyDetails;
