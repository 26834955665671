// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    
      <div className="footer">
        <div className="copyright">
          <p>
            Copyright © 2012- {currentYear}
            <Link to="https://www.codeachi.com" target="_blank" style={{ color: "#0c8272" }}>
              {" "}
              CodeAchi Technologies
            </Link>
          </p>
        </div>
      </div>
    
  );
};

export default Footer;
