import React, { useState, useEffect } from "react";
import axios from "../../axiosConfig";

const InvoiceSettings = () => {
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [optionalInvoicePrefix, setOptionalInvoicePrefix] = useState("");
  const [quotationPrefix, setQuotationPrefix] = useState("");
  const [receiptPrefix, setReceiptPrefix] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const getSettings = async () => {
    try {
      const response = await axios.get("api/settings/invoice");
      //console.log(response.data);
      setInvoicePrefix(response.data.data.prefix);
      setOptionalInvoicePrefix(response.data.data.optionalPrefix);
      setQuotationPrefix(response.data.data.quotationPrefix);
      setReceiptPrefix(response.data.data.receiptPrefix);
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };
  useEffect(() => {
    getSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");
    try {
      const data = {
        prefix: invoicePrefix,
        optionalPrefix: optionalInvoicePrefix,
        quotationPrefix: quotationPrefix,
        receiptPrefix: receiptPrefix,
      };
      const response = await axios.post("api/settings/invoice", {data});
      setSuccessMessage("Settings saved successfully.");
    } catch (error) {
      setErrorMessage("Error saving settings. Please try again.");
      console.error("Error saving settings:", error);
    }
  };

  const closeMessage = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Settings</h4>
      </div>
      <div className="card-body">
        <div className="basic-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Invoice Prefix:</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={invoicePrefix}
                  onChange={(e) => setInvoicePrefix(e.target.value)}
                  onFocus={() => closeMessage()}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">
                Optional Invoice Prefix:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={optionalInvoicePrefix}
                  onChange={(e) => setOptionalInvoicePrefix(e.target.value)}
                  onFocus={() => closeMessage()}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">
                Quotation Prefix:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={quotationPrefix}
                  onChange={(e) => setQuotationPrefix(e.target.value)}
                  onFocus={() => closeMessage()}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Receipt Prefix:</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  value={receiptPrefix}
                  onChange={(e) => setReceiptPrefix(e.target.value)}
                  onFocus={() => closeMessage()}
                />
              </div>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                {errorMessage}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={closeMessage}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <div className="form-group row justify-content-end">
              <div className="col-sm-9">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-new"
                >
                  <i className="fa fa-save mr-2"></i> Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSettings;
