import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import axios from "../axiosConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TextField,
  TablePagination,
  Grid,
  TableSortLabel,
} from "@material-ui/core";

import ReactModal from "react-modal";
ReactModal.setAppElement("#root");

const UserList = () => {
  const [data, setData] = useState([]);
  //-------------------Modals ----------------------------
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [userData, setUserData] = useState({
    name: "",
    isActive: false,
    phone: "",
    role: "",
  });

  const getData = async () => {
    try {
      const response = await axios.get("/api/user/all", {
        withCredentials: true,
      });

      setData(response.data);
    } catch (error) {
      setData([]);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const filteredData = data.filter((item) =>
    ["isActive", "createdAt", "name", "email", "phone", "role"].some((key) => {
      const value = item[key];
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  const handleSort = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  const sortedData = filteredData.slice().sort((a, b) => {
    const aValue = a[sortedField];
    const bValue = b[sortedField];
    if (aValue < bValue) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const statusText = (isActive) => {
    if (isActive) {
      return (
        <>
          <i
            className="fa-solid fa-check text-dark"
            style={{ marginTop: "5px" }}
          />
          <p className="ml-2"> Active </p>
        </>
      );
    } else {
      return (
        <>
          <i className="fa-solid fa-skull-crossbones text-danger" />
          <p className="ml-2 text-danger"> Inactive </p>
        </>
      );
    }
  };

  const formattedCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    let amPm = "AM";

    // Convert 24-hour time to 12-hour time
    if (hours >= 12) {
      amPm = "PM";
      hours %= 12;
    }
    if (hours === 0) {
      hours = 12;
    }

    // Format hours with leading zero
    hours = hours.toString().padStart(2, "0");

    return `${day}/${month}/${year} `;
  };
  const [access, setAccess] = useState([]);
  const [action, setAction] = useState("");
  const [uid, setUid] = useState({});

  const getAccess = async (email) => {
    try {
      const response = await axios.post(
        "/api/user/get-access",
        { email },
        {
          withCredentials: true,
        }
      );

      setAccess(response.data.access);
    } catch (error) {
      setAccess([]);
    }
  };

  const [accessList, setAccessList] = useState([]);

  const getAccessList = async (email) => {
    try {
      const response = await axios.get("/api/user/get-access-list", {
        withCredentials: true,
      });

      setAccessList(response.data.accessList);
    } catch (error) {
      setAccessList([]);
    }
  };

  const openEditModal = async (e) => {
    setAction(e.currentTarget.getAttribute("action"));
    setUid(JSON.parse(e.currentTarget.getAttribute("uid")));
    const user = JSON.parse(e.currentTarget.getAttribute("uid"));
    setUserData({
      isActive: user.isActive,
      name: user.name,
      phone: user.phone,
      role: user.role,
    });

    if (e.currentTarget.getAttribute("action") === "setAccess") {
      const email = JSON.parse(e.currentTarget.getAttribute("uid")).email;

      await getAccess(email);
      await getAccessList();
    }
    closeError();
    openModal();
  };

  const [selectedItems, setSelectedItems] = useState([]);

  // Function to handle checkbox toggle
  const handleCheckboxChange = (value) => {
    if (selectedItems.includes(value)) {
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  const [newAccessItem, setNewAccessItem] = useState("");
  const [selectedAccess, setSelectedAccess] = useState([]);

  const handleDeleteSelectedAccess = () => {
    // Remove selected access items from the access list
    const updatedAccess = access.filter(
      (item) => !selectedItems.includes(item)
    );
    setAccess(updatedAccess);
    // Clear selected items
    setSelectedItems([]);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const closeError = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleAddAccess = () => {
    if (newAccessItem.trim() !== "") {
      // Check if the new access item already exists in the access list
      if (!access.includes(newAccessItem.trim())) {
        // Add new access item to the access list
        setAccess((prevAccess) => [...prevAccess, newAccessItem.trim()]);
      } else {
        setErrorMessage("This access is already present for this user");
      }
      // Clear the input box
      setNewAccessItem("");
    } else {
      setErrorMessage("Can not add a invalid access");
    }
  };

  const [successMessage, setSuccessMessage] = useState("");

  const handleSaveAccess = async () => {
    // Send the access list to the server for saving

    if (!selectAllAccess) {
      const email = uid.email;
      // You can make an API call here to save the access list
      try {
        const response = await axios.post("/api/user/set-access", {
          email,
          access: JSON.stringify(access), // Stringify the access array
        });

        setSuccessMessage(response.data.message);
      } catch (error) {
        // Handle error
        setErrorMessage(error.message);
        console.error(error);
      }
    } else {
      const role = uid.role;
      // You can make an API call here to save the access list
      try {
        const response = await axios.post("/api/user/set-access-all", {
          role,
          access: JSON.stringify(access), // Stringify the access array
        });
        console.log(response.data);
        setSuccessMessage(response.data.message);
      } catch (error) {
        // Handle error
        setErrorMessage(error.message);
        console.error(error);
      }
    }
  };

  const [selectAllAccess, setSelectAllAccess] = useState(false);

  const handleSelectAllAccessChange = (e) => {
    setSelectAllAccess(e.target.checked);
  };

  const [userPassword, setUserPassword] = useState("");

  const handleSavePassword = async () => {
    if (!userPassword) {
      setErrorMessage("provide a valid password");
      return;
    }
    const email = uid.email;
    // You can make an API call here to save the access list
    try {
      const response = await axios.post("/api/user/change-user-password", {
        email,
        newPassword: userPassword, // Stringify the access array
      });
      console.log(response.data);
      setSuccessMessage(response.data.message);
    } catch (error) {
      // Handle error
      setErrorMessage(error.message);
      console.error(error);
    }
  };

  const handleSavedata = async () => {
    const email = uid.email;
    // You can make an API call here to save the access list
    try {
      const response = await axios.post("/api/user/edit", {
        email,
        userData, // Stringify the access array
      });
      console.log(response.data);
      setSuccessMessage(response.data.message);
    } catch (error) {
      // Handle error
      setErrorMessage(error.message);
      console.error(error);
    }
    getData();
  };

  const handleDelete = async () => {
    const email = uid.email;
    // You can make an API call here to save the access list
    try {
      const response = await axios.post("/api/user/delete", {
        email,
      });
      console.log(response.data);
      setSuccessMessage(response.data.message);
    } catch (error) {
      // Handle error
      setErrorMessage(error.message);
      console.error(error);
    }
    getData();
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />

        <title>User List| CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="">
                    <div className="pb-2">
                      <Grid container spacing={12}>
                        <Grid item xs={12} sm={9} md={9}>
                          <h4 className="card-title">User List</h4>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <TextField
                            label="Search..."
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            size="small"
                            fullWidth
                            InputProps={{
                              style: {
                                borderRadius: 0,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <TableContainer component={Paper}>
                      <Table
                        className="table table-bordered table-striped verticle-middle table-responsive-sm"
                        id="ApilogTable"
                      >
                        <TableHead className="thead-primary">
                          <TableRow>
                            <TableCell style={{ textAlign: "center" }}>
                              <TableSortLabel
                                active={sortedField === "isActive"}
                                direction={sortDirection}
                                onClick={() => handleSort("isActive")}
                              >
                                Status
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <TableSortLabel
                                active={sortedField === "createdAt"}
                                direction={sortDirection}
                                onClick={() => handleSort("createdAt")}
                              >
                                Created At
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <TableSortLabel
                                active={sortedField === "name"}
                                direction={sortDirection}
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <TableSortLabel
                                active={sortedField === "email"}
                                direction={sortDirection}
                                onClick={() => handleSort("email")}
                              >
                                Email
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <TableSortLabel
                                active={sortedField === "phone"}
                                direction={sortDirection}
                                onClick={() => handleSort("phone")}
                              >
                                Phone
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              <TableSortLabel
                                active={sortedField === "role"}
                                direction={sortDirection}
                                onClick={() => handleSort("role")}
                              >
                                Role
                              </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedData
                            .slice(page * pageSize, page * pageSize + pageSize)
                            .map((row, index) => (
                              <TableRow key={index}>
                                <TableCell style={{ textAlign: "center" }}>
                                  <div className="row ml-2 mr-2 mt-3">
                                    {statusText(row.isActive)}
                                  </div>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {formattedCreatedAt(row.createdAt)}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {row.name}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {row.email}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  {row.phone}
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <span className="badge badge-primary">
                                    {row.role}
                                  </span>
                                </TableCell>
                                <TableCell style={{ textAlign: "center" }}>
                                  <span>
                                    <div className="row justify-content-center">
                                      <div
                                        onClick={openEditModal}
                                        action="setAccess"
                                        uid={JSON.stringify(row)}
                                        className="col-lg-3 col-md-3 col-sm-3 col-3 hand-cursor div-hover"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Change Permissions"
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <i className="fas fa-user-lock color-muted" />
                                      </div>
                                      <div
                                        onClick={openEditModal}
                                        action="changePassword"
                                        uid={JSON.stringify(row)}
                                        className="col-lg-3 col-md-3 col-sm-3 col-3 hand-cursor div-hover"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Change Password"
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <i className="fas fa-key color-muted" />
                                      </div>
                                      <div
                                        onClick={openEditModal}
                                        action="edit"
                                        uid={JSON.stringify(row)}
                                        className="col-lg-3 col-md-3 col-sm-3 col-3 hand-cursor div-hover"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <i className="fa fa-pencil color-muted" />
                                      </div>
                                      <div
                                        className="col-lg-3 col-md-3 col-sm-3 col-3 hand-cursor div-hover"
                                        action="delete"
                                        uid={JSON.stringify(row)}
                                        onClick={openEditModal}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                        style={{
                                          textAlign: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <i className="fas fa-trash-alt color-danger" />
                                      </div>
                                    </div>
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />

                  {/* Items count */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        ariaHideApp={true}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 1000,
          },
          content: {
            width: "90%",
            height: "80%",
            maxWidth: "600px",
            maxHeight: "90%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1001,
            padding: "20px",
            overflow: "auto",
          },
        }}
        contentLabel="Example Modal"
      >
        <button
          onClick={closeModal}
          style={{
            position: "absolute", 
            top: "10px", 
            right: "10px", 
            border: "none", 
            background: "transparent", 
            fontSize: "30px",
            cursor: "pointer",
            zIndex: "1000",
          }}
        >
          &times;{" "}
          {/* HTML entity for the multiplication sign, commonly used as a close icon */}
        </button>
        <div className="row">
          <div className="col-md-12">
            {action === "setAccess" && (
              <div className="row mt-4">
                <div className="col-md-6">
                  <p>
                   <strong> Name/Role:</strong> {uid.name}/{uid.role}{" "}
                  </p>
                </div>
                <div className="col-md-6">
                  <p><strong>Email:</strong> {uid.email} </p>
                </div>
                <div
                  className="ml-3 col-md-5"
                  style={{
                    maxHeight: "250px",
                    minHeight: "250px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                  }}
                >
                  {/* Render checkboxes for each item in the data array */}
                  {access &&
                    access.map((item, index) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          className="mr-2"
                          id={`checkbox-${index}`}
                          checked={selectedItems.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                        />
                        <label htmlFor={`checkbox-${index}`}>{item}</label>
                      </div>
                    ))}
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-danger mr-2 smallmar "
                        onClick={handleDeleteSelectedAccess}
                      >
                        <i className="fas fa-trash-alt color-danger" /> Delete
                        Selected
                      </button>
                    </div>
                    <div className="col-md-12">
                      <div className="row mt-3">
                        <input
                          list="accessOptions"
                          onFocus={closeError}
                          className="col-8 form-control mr-2 ml-3"
                          placeholder="Search or select an existing item..."
                          value={newAccessItem}
                          onChange={(e) => setNewAccessItem(e.target.value)}
                        />

                        <datalist id="accessOptions">
                          {accessList &&
                            accessList.map((item, index) => (
                              <>
                                <option value={item} />
                              </>
                            ))}
                        </datalist>
                        <button
                          className="col-2 btn btn-primary mr-4"
                          onClick={handleAddAccess}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="row ">
                    <div className="col-md-7">
                      <div className="d-flex">
                        <div className="form-check ml-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="select-all-checkbox"
                            checked={selectAllAccess}
                            onChange={handleSelectAllAccessChange}
                          />
                          <label
                            className="d-block"
                            htmlFor="select-all-checkbox"
                            style={{marginTop:"3px"}}
                          >
                            Change this access for all
                            <span className="font-weight-bold">
                              {" "}
                              [{uid.role}]{" "}
                            </span>
                            users
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5">
                      <button
                        className="btn btn-success "
                        onClick={handleSaveAccess}
                      >
                        <i class="fas fa-save mr-2"></i>
                        Save Access
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center mt-4">
                  {errorMessage && (
                    <p className="text-danger">{errorMessage}</p>
                  )}
                  {successMessage && (
                    <p className="text-success">{successMessage}</p>
                  )}
                </div>
              </div>
            )}

            {action === "changePassword" && (
              <div className="mt-4">
                <div className="row mt-4 pt-4 ml-4 pl-4">
                  <div className="col-lg-6 ">
                    <input
                      className="form-control"
                      type="password"
                      onFocus={closeError}
                      value={userPassword}
                      onChange={(e) => setUserPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 smallmar">
                    <button
                      className="btn btn-success "
                      onClick={handleSavePassword}
                    >
                      <i class="fas fa-save mr-2"></i>
                      Change Password
                    </button>
                  </div>
                  <div className="col-md-12 text-center mt-4">
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                    {successMessage && (
                      <p className="text-success">{successMessage}</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {action === "edit" && (
              <div className="mt-4">
                <div className="row mt-4 pt-4 ">
                  <div className="col-md-6">
                    <input
                      className="form-control m-2"
                      type="text"
                      placeholder="Name"
                      onFocus={closeError}
                      value={userData.name}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="col-md-6">
                    <input
                      className="form-control m-2"
                      type="text"
                      placeholder="Phone"
                      onFocus={closeError}
                      value={userData.phone}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          phone: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control m-2"
                      type="text"
                      placeholder="Role"
                      onFocus={closeError}
                      value={userData.role}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          role: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className="col-md-6">
                    <idv className="row">
                      <div className="d-flex">
                        <div className="form-check mt-3">
                          <input
                            className="form-check-input ml-2"
                            type="checkbox"
                            id="isActive"
                            checked={userData.isActive}
                            onChange={(e) =>
                              setUserData((prevUserData) => ({
                                ...prevUserData,
                                isActive: e.target.checked,
                              }))
                            }
                          />
                          <label
                            className="form-check-label d-block ml-4"
                            htmlFor="select-all-checkbox"
                          >
                            Is Active
                          </label>
                        </div>
                      </div>
                    </idv>
                  </div>

                  <div className="col-md-6 smallmar">
                    <button
                      className="btn btn-success ml-2"
                      onClick={handleSavedata}
                    >
                      <i class="fas fa-save mr-2"></i>
                      Save
                    </button>
                  </div>
                  <div className="col-md-12 text-center mt-4">
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                    {successMessage && (
                      <p className="text-success">{successMessage}</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {action === "delete" && (
              <div className="mt-4">
                <div className="row mt-4 pt-4 ml-4 pl-4">
                  <div className="col-md-12">
                    <p>
                      {" "}
                      Do you really want to delete - "{uid.name}" ({uid.email})?{" "}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-danger " onClick={handleDelete}>
                      <i class="fas fa-save mr-2"></i>
                      Delete
                    </button>
                  </div>
                  <div className="col-md-12 text-center mt-4">
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                    {successMessage && (
                      <p className="text-success">{successMessage}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </Layout>
  );
};

export default UserList;
