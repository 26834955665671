import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";


const ProductVersion = () => {
  const [subtotal, setSubtotal] = useState(0);
  const [expectedGrandTotal, setExpectedGrandTotal] = useState(0);
  const [discountNeeded, setDiscountNeeded] = useState(0);
 
  const calculateDiscountNeeded = () => {
    const discount = subtotal - expectedGrandTotal / 1.239;
    setDiscountNeeded(discount);
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Product Version | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        <div className="row justify-content-center" style={{padding:"12px"}}>
          <div className="col-md-6">
            <div className="form-group">
              <div className="input-label-container">
              <label className="input-label col-sm-3 " style={{paddingLeft:"0"}}>Subtotal:</label>
                <input
                col-sm-9 
                  type="number"
                  className="form-control centered-input"
                  value={subtotal}
                  onChange={(e) => setSubtotal(parseFloat(e.target.value))}
                />
             
              </div>
            </div>

            <div className="form-group">
              <div className="input-label-container">
              <label className="input-label">Expected Grand Total:</label>
                <input
                  type="number"
                  className="form-control centered-input"
                  value={expectedGrandTotal}
                  onChange={(e) =>
                    setExpectedGrandTotal(parseFloat(e.target.value))
                  }
                />
              
              </div>
            </div>
            <div className="text-center">
            <label className="discount-label">
              
              <strong>Discount Needed:</strong> {discountNeeded.toFixed(4)}
            </label>     <br/>     <br/>
            <button
              type="button"
              className="btn btn-primary"
              onClick={calculateDiscountNeeded}
            >
              Calculate Discount Needed
            </button>
            </div>
             <br/>
             <br/>
             <br/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductVersion;
