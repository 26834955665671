import React, { useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/auth/authSlice";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const authStatus = useSelector((state) => state.auth.status);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authError = useSelector((state) => state.auth.error);
  const prevLocation = localStorage.getItem('prevLocation') || '/auth/dashboard';

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (isAuthenticated && prevLocation) {
      navigate(prevLocation);
    }
  }, [isAuthenticated, prevLocation]);

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="CodeAchi Technologies Pvt Ltd" />
        <meta name="keywords" content="Software Company, CodeAchi, CodeAchi Technologies" />
        <title>Login | CodeAchi Technologies - Your Software Solutions Partner</title>
        <meta name="description" content="Leading provider of software solutions including Library Management, Grievance Management, and Visitor Management systems. Trusted by schools, colleges, and organizations worldwide." />
        <link rel="canonical" href="https://www.codeachi.com" />
        <meta property="og:title" content="Your Content Title" />
        <meta property="og:description" content="Your content description" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.example.com/page" />
        <meta property="og:image" content="http://www.example.com/image.jpg" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Your Content Title" />
        <meta name="twitter:description" content="Your content description" />
        <meta name="twitter:image" content="http://www.example.com/image.jpg" />
      </Helmet>

      <div className="authincation">
        <div className="container-fluid auth-bg">
          <div className="row justify-content-center align-items-center auth-padding">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <Link
                        to="#"
                        className="text-nowrap logo-img text-center d-block py-3 w-100"
                      >
                        <img src="./logo-red-black.png" alt="file-fender" />
                      </Link>
                      <form action="">
                        <div className="form-group">
                          <label><strong>Email</strong></label>
                          <input
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label><strong>Password</strong></label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="text-center">
                          {authStatus === "loading" && <p>Loading...</p>}
                          {authError && <p>Error: {authError}</p>}
                          <button
                            type="button"
                            onClick={handleLogin}
                            className="btn btn-primary btn-block dif-color"
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3 text-center">
                        <p style={{ fontSize: 16 }}>Forgot password? Contact admin</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
