import React, { useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";

const Dashboard = () => {
 
  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        
        <title>Dashboard | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        {/* row */}
        <div className="container-fluid">
          <div className="row page-titles mx-0">
            <div className="col-sm-6 p-md-0">
              <div className="welcome-text">
                <h4 style={{ color: "#0c8272" }}>Hi, welcome back!</h4>
                <p className="mb-0">Dashboard</p>
              </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Generate Keys</a>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="card">
                <div className="stat-widget-two card-body">
                  <div className="stat-content">
                    <div className="stat-text">Total Keys Generated</div>
                    <div className="stat-digit">
                      <i className="fa fa-usd" />
                      8500
                    </div>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-success w-85"
                      role="progressbar"
                      aria-valuenow={85}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card">
                <div className="stat-widget-two card-body">
                  <div className="stat-content">
                    <div className="stat-text">Key Activated</div>
                    <div className="stat-digit">
                      <i className="fa fa-usd" />
                      7800
                    </div>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-primary w-75"
                      role="progressbar"
                      aria-valuenow={78}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card">
                <div className="stat-widget-two card-body">
                  <div className="stat-content">
                    <div className="stat-text">Total Blocked Keys</div>
                    <div className="stat-digit">
                      <i className="fa fa-usd" /> 500
                    </div>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-warning w-50"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card">
                <div className="stat-widget-two card-body">
                  <div className="stat-content">
                    <div className="stat-text">Change Password</div>
                    <div className="stat-digit">
                      <i className="fa fa-usd" />
                      650
                    </div>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-danger w-65"
                      role="progressbar"
                      aria-valuenow={65}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
              {/* /# card */}
            </div>
            {/* /# column */}
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Sales Overview</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-12 col-lg-8">
                      <div id="morris-bar-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="card">
                <div className="card-body text-center">
                  <div className="m-t-10">
                    <h4 className="card-title">Customer Feedback</h4>
                    <h2 className="mt-3">385749</h2>
                  </div>
                  <div
                    className="widget-card-circle mt-5 mb-5"
                    id="info-circle-card"
                  >
                    <i className="ti-control-shuffle pa" />
                  </div>
                  <ul className="widget-line-list m-b-15">
                    <li className="border-right">
                      92% <br />
                      <span className="text-success">
                        <i className="ti-hand-point-up" /> Positive
                      </span>
                    </li>
                    <li>
                      8% <br />
                      <span className="text-danger">
                        <i className="ti-hand-point-down" />
                        Negative
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
