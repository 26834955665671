import React, { useState, useEffect } from "react";
import axios from "../../axiosConfig";

const RegistrationDetails = ({ machineId }) => {
  const [registration, setRegistration] = useState(null);

  useEffect(() => {
    const fetchRegistrationDetails = async () => {
      try {
        const response = await axios.post("/api/product/registration", {
          machineId,
        });
        setRegistration(response.data);
      } catch (error) {
        console.error("Error fetching registration details:", error);
      }
    };

    fetchRegistrationDetails();
  }, []);

  if (!registration) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yy = date.getFullYear().toString().substr(-2);
      let hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? String(hours).padStart(2, "0") : "12"; // the hour '0' should be '12'

      return `${dd}/${mm}/${yy} ${hours}:${minutes}:${seconds} ${ampm}`;
    } else {
      return "N/A";
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">Registration Details</h2>
      </div>
      <div className="card-body text-dark mt-0 pt-0">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Installed at:</strong>{" "}
                {formatDate(registration.createdAt)}
              </div>
              <div className="col-md-6">
                <strong>Updated:</strong> {formatDate(registration.updatedAt)}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Valid Till:</strong>{" "}
                {formatDate(registration.validTill)}
              </div>
              <div className="col-md-6">
                <strong>last Used:</strong> {formatDate(registration.lastUsed)}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Machine ID:</strong> {registration.machineId}
              </div>
              <div className="col-md-6">
                <strong>Hardware ID:</strong> {registration.hardwareId}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Client:</strong> {registration.client.name}
              </div>
              <div className="col-md-6">
                <strong>Designation:</strong> {registration.client.designation}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Phone:</strong> {registration.client.phone}
              </div>
              <div className="col-md-6">
                <strong>Email:</strong> {registration.client.email}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Organization:</strong> {registration.organization.name}
              </div>
              <div className="col-md-6">
                <strong>Website:</strong> {registration.organization.website}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Address:</strong> {registration.organization.address}
              </div>
              <div className="col-md-6">
                <strong>Country:</strong> {registration.organization.country}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Product Name:</strong> {registration.product.name}
              </div>
              <div className="col-md-6">
                <strong>License Key:</strong> {registration.licenseKey}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Blocked ?:</strong>{" "}
                <span className="badge badge-primary">
                  {" "}
                  {registration.isBlocked ? "Yes" : "No"}{" "}
                </span>
              </div>
              <div className="col-md-6">
                <strong>Reason:</strong> {registration.blockedReason}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Uninstalled:</strong>{" "}
                <span className="badge badge-primary">
                  {registration.isUninstalled ? "Yes" : "No"}
                </span> <br></br>
                <strong>Uninstall Reason:</strong>{" "}
                {registration.uninstallationReason}
              </div>
              <div className="col-md-6">
                <strong>Date:</strong> {formatDate(registration.uninstallDate)}
              </div>
            </div>
          </li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
                <strong>Source:</strong> {registration.source}
              </div>
              <div className="col-md-6">
                <strong>Software Version:</strong>{" "}
                {registration.softwareVersion}
              </div>
            </div>
          </li>
        </ul>
        <button
          className="btn btn-primary mt-3"
          onClick={() => {
            // Copy registration details to clipboard
            navigator.clipboard.writeText(
              JSON.stringify(registration, null, 2)
            );
          }}
        >
          Copy Registration Details
        </button>
      </div>
    </div>
  );
};

export default RegistrationDetails;
