import React from "react";
import ReactDOM from "react-dom/client";


// Component and utility imports
import App from "./App";
import reportWebVitals from "./reportWebVitals";


// App rendering
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// Web Vitals report
reportWebVitals();
