// src/components/Layout.js
import React, { useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = (newIsActive) => {
    setIsActive(newIsActive);
  };
  
  return (
    <>
      <div id="main-wrapper" className={`show ${isActive ? "menu-toggle" : ""}`}>
        {" "}
        <Header isActive={isActive} onToggle={handleToggle} />
        <Sidebar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
