import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkAuthStatus } from "./store/auth/authSlice";
import AuthNotFound from "./pages/AuthNotFound";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";

import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Products from "./pages/Products";
import ProductsRegistration from "./pages/ProductsRegistration";
import ProductsVersion from "./pages/ProductVersion";

import GenerateKeys from "./pages/KeyGenerate";
import KeyDetails from "./pages/KeyDetails";
import KeyActivation from "./pages/KeyActivation";

import OrderDetails from "./pages/OrderDetails";
import OrderPendingCart from "./pages/OrdersPendingCart";
import OrderCoupons from "./pages/OrderCoupons";

import ContactDetails from "./pages/ContactDetails";
import ContactRequest from "./pages/ContactRequest";

import SystemsTradersGenerate from "./pages/SystemsTradersKeyGenerate";
import SystemsTradersKeyDetails from "./pages/SystemsTradersKeyDetails";

import Profile from "./pages/Profile";
import AddUser from "./pages/AddUser";
import UserList from "./pages/UserList";
import Settings from "./pages/Settings";
import AnalyticsLog from "./pages/AnalyticsLog";

import PricingCalculator from "./pages/PricingCalculator";

const AppRoutes = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = JSON.parse(localStorage.getItem("navHistory")) || [""];

  const [navHistory, setNavHistory] = useState(() => {
    const storedHistory = localStorage.getItem("navHistory");
    return storedHistory ? JSON.parse(storedHistory) : [];
  });

  // Function to add a new node to the navigation history
  const addToHistory = (newNode) => {
    const updatedHistory = [...navHistory, newNode];
    const truncatedHistory = updatedHistory.slice(-4);
    setNavHistory(truncatedHistory);
    localStorage.setItem("navHistory", JSON.stringify(truncatedHistory));
  };

  // Store previous location when the location changes
  useEffect(() => {
    const prevLocation = localStorage.getItem("prevLocation");
    if (
      history[history.length - 1] !== location.pathname &&
      prevLocation !== location.pathname &&
      location.pathname !== "/" &&
      location.pathname !== "/login"
    ) {
      localStorage.setItem("prevLocation", location.pathname);
      addToHistory(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      {/* Authenticated routes wrapped in ProtectedRoute */}
      <Route element={<ProtectedRoute />}>
        <Route path="/auth/dashboard" element={<Dashboard />} />
        <Route path="/auth/products" element={<Products />} />
        <Route
          path="/auth/products/registration"
          element={<ProductsRegistration />}
        />
        <Route path="/auth/products/version" element={<ProductsVersion />} />
        <Route path="/auth/generate-keys" element={<GenerateKeys />} />
        <Route path="/auth/keys-details" element={<KeyDetails />} />
        <Route path="/auth/keys-activation" element={<KeyActivation />} />

        <Route path="/auth/order" element={<OrderDetails />} />
        <Route path="/auth/pending-carts" element={<OrderPendingCart />} />
        <Route path="/auth/coupons" element={<OrderCoupons />} />

        <Route path="/auth/contact" element={<ContactDetails />} />
        <Route path="/auth/contact-request" element={<ContactRequest />} />

        <Route path="/auth/systemtraders/generate-key" element={<SystemsTradersGenerate />} />
        <Route path="/auth/systemtraders/key-details" element={<SystemsTradersKeyDetails />} />

        <Route path="/auth/profile" element={<Profile />} />
        <Route path="/auth/user/list" element={<UserList />} />
        <Route path="/auth/user/add" element={<AddUser />} />
        <Route path="/auth/settings" element={<Settings />} />
        <Route path="/auth/analytics" element={<AnalyticsLog />} />
        <Route
          path="/auth/pricing-calculator"
          element={<PricingCalculator />}
        />
        <Route path="/auth/*" element={<AuthNotFound />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
