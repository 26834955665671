import React, { useEffect, useState } from "react";
import axios from "../../axiosConfig";

const BlockMachine = ({ machineId }) => {
  const [isBlocked, setIsBlocked] = useState();
  const [blockedReason, setBlockedReason] = useState();
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [register, setRegister] = useState({});

  const handleStatusChange = (e) => {
    setIsBlocked(e.target.checked);
  };

  const handleReasonChange = (e) => {
    setBlockedReason(e.target.value);
  };

  const getStatus = async () => {
    try {
      const response = await axios.post("/api/product/registration", {
        machineId,
      });
      setRegister(response.data);
      setIsBlocked(response.data.isBlocked);
      setBlockedReason(response.data.blockedReason);
    } catch (error) {
      setResponseMessage("Error saving block status: " + error.message);
      setResponseType("error");
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/product/registration/block", {
        machineId,
        isBlocked,
        blockedReason,
      });
      setResponseMessage("Block status saved.");
      setResponseType("success");
    } catch (error) {
      setResponseMessage("Error saving block status: " + error.message);
      setResponseType("error");
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">Block machine</h2>
      </div>
      <div className="card-body text-dark">
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Machine ID: {machineId}</li>

          <li className="list-group-item">
            <div className="row">
              <div className="col-md-4">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isBlocked}
                    onChange={handleStatusChange}
                    id="isBlocked"
                  />
                  <label className="form-check-label" htmlFor="isBlocked">
                    Blocked
                  </label>
                </div>
              </div>
              <div className="col-md-8">
                {isBlocked && (
                  <div className="form-group">
                    <label htmlFor="blockedReason">Reason:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="blockedReason"
                      value={blockedReason}
                      onChange={handleReasonChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </li>
        </ul>
        <div className="row">
          <div className="col-md-4">
            <button className="btn btn-primary mt-3" onClick={handleSubmit}>
              Submit
            </button>
          </div>
          <div className="col-md-8">
            {responseMessage && (
              <p
                className={`mt-3 text-${
                  responseType === "success" ? "success" : "danger"
                }`}
              >
                {responseMessage}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockMachine;
