import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import axios from "../axiosConfig";

const AddUser = () => {
  const [resError, setResError] = useState("");
  const [resSuccess, setResSuccess] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    isActive: false,
    phone: "",
    role: "",
  });
  const clearMessage = () => {
    setResError("");
    setResSuccess("");
  };

  const HandleAddUser = async () => {
    console.log("here");

    try {
      const response = await axios.post("/api/user/add", {
        userData,
      });
      console.log(response.data);
      setResSuccess(response.data.message);
    } catch (error) {
      // Handle error
      setResError(error.message);
      console.error(error);
    }
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        
        <title>User List| CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div
                    className="card-info-wrap ml-2 mr-2 col-md-12"
                    style={{
                      position: "relative",
                      border: "1px solid #ced4da",
                      borderRadius: "4px",
                      padding: "20px",
                      paddingTop: "30px",
                      marginBottom: "20px",
                      backgroundColor: "#fff",
                      color:"#01483e"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        left: "20px",
                        backgroundColor: "#fff",
                        padding: "0 10px",
                        fontWeight: "bold",
                        color: "#0127122",
                      }}
                    >
                      Add a New User
                    </div>

                    <div className="wrap">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <strong>Name</strong>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              onFocus={clearMessage}
                              value={userData.name}
                              onChange={(e) =>
                                setUserData((prevUserData) => ({
                                  ...prevUserData,
                                  name: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <strong>Phone</strong>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              onFocus={clearMessage}
                              value={userData.phone}
                              onChange={(e) =>
                                setUserData((prevUserData) => ({
                                  ...prevUserData,
                                  phone: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <strong>Email</strong>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              onFocus={clearMessage}
                              value={userData.email}
                              onChange={(e) =>
                                setUserData((prevUserData) => ({
                                  ...prevUserData,
                                  email: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <strong>Password</strong>
                            </label>

                            <input
                              type="password"
                              className="form-control"
                              onFocus={clearMessage}
                              value={userData.password}
                              onChange={(e) =>
                                setUserData((prevUserData) => ({
                                  ...prevUserData,
                                  password: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              <strong>Role</strong>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              onFocus={clearMessage}
                              value={userData.role}
                              onChange={(e) =>
                                setUserData((prevUserData) => ({
                                  ...prevUserData,
                                  role: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex">
                            <div  style={{marginTop:"2.1rem"}}>
                              <input
                                className="form-check-input ml-2"
                                type="checkbox"
                                id="isActive"
                                checked={userData.isActive}
                                onChange={(e) =>
                                  setUserData((prevUserData) => ({
                                    ...prevUserData,
                                    isActive: e.target.checked,
                                  }))
                                }
                              />
                              <label
                                className="form-check-label d-block"
                                htmlFor="select-all-checkbox"
                              >
                                Is Active
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <center>  
                         <div className="col-lg-4 col-md-4 col-12 mt-4 ">

                      <div className="text-center">
                        {resError && (
                          <div className="danger text-danger mb-2">
                            Error: {resError}
                          </div>
                        )}
                        {resSuccess && (
                          <div className="success text-success mb-2">
                            {" "}
                            {resSuccess}
                          </div>
                        )}
                        <button
                          type="button"
                          onClick={HandleAddUser}
                          className="btn btn-primary btn-block dif-color"
                        >
                          Add User
                        </button>
                      </div>
                      </div></center>
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddUser;




