import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axiosConfig';

// Define async thunk actions for login, logout, and status check
export const login = createAsyncThunk(

  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/auth/login', { email, password }, { withCredentials: true });
      console.log("recponse",response.data )
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await axios.get('/api/auth/logout', { withCredentials: true });
});

export const checkAuthStatus = createAsyncThunk('auth/status', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get('/api/auth/status', { withCredentials: true });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});



// Define the initial state of the auth slice
const initialState = {
  user: null,
  isAuthenticated: false,
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticated = true;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.status = 'failed';
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
        state.status = 'idle';
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isAuthenticated = action.payload.authenticated;
        state.status = 'succeeded';
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.status = 'failed';
      });
  },
});

export default authSlice.reducer;
