import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layouts/Layout";

import InvoiceSettings from "../components/settings/Invoice";
import AccessListSettings from "../components/settings/AccessList";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        
        <title>Settings | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        {/* row */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="tabs">
                <button
                  onClick={() => handleTabClick(0)}
                  className={activeTab === 0 ? "active" : ""}
                >
                  Invoice / Quotation
                </button>
                <button
                  onClick={() => handleTabClick(1)}
                  className={activeTab === 1 ? "active" : ""}
                >
                  User Settings
                </button>
                <button
                  onClick={() => handleTabClick(2)}
                  className={activeTab === 2 ? "active" : ""}
                >
                  Others
                </button>
              </div>
              <div className="tab-content">
                {activeTab === 0 && <InvoiceSettings />}
                {activeTab === 1 && <AccessListSettings />}
                {activeTab === 2 && <div className="text-dark">Content for Tab 3</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
