import React, { useState, useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import axios from "../axiosConfig";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  TableSortLabel,
  Grid,
} from "@material-ui/core";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const AnalyticsLog = () => {
  const [logs, setLogs] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const fetchLogs = async () => {
    try {
      const response = await axios.get("/api/analytics/not-found"); 
      setLogs(response.data);
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleSort = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  const filteredLogs = logs.filter((log) =>
    Object.values(log).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedLogs = filteredLogs.slice().sort((a, b) => {
    const aValue = a[sortedField];
    const bValue = b[sortedField];
    if (aValue < bValue) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Page Analytics | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="pb-2">
                      <Grid container spacing={2}>
                      <Grid item xs={12} sm={9} md={9}>
                          <h4 className="card-title">Analytics Logs</h4>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <TextField
                            label="Search..."
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            size="small"
                            fullWidth
                            
                            InputProps={{
                              style: {
                                borderRadius: 0, 
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                        <Table className="table table-bordered table-striped verticle-middle table-responsive-sm">
                          <TableHead className="thead-primary">
                            <TableRow>
                              <TableCell>
                                <TableSortLabel
                                  active={sortedField === "createdAt"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("createdAt")}
                                >
                                  Time
                                </TableSortLabel>
                              </TableCell>
                              <TableCell>URL</TableCell>
                              <TableCell>Counter</TableCell>
                              <TableCell>
                                <TableSortLabel
                                  active={sortedField === "updatedAt"}
                                  direction={sortDirection}
                                  onClick={() => handleSort("updatedAt")}
                                >
                                  Last Log
                                </TableSortLabel>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedLogs
                              .slice(page * pageSize, page * pageSize + pageSize)
                              .map((log) => (
                                <TableRow key={log._id}>
                                  <TableCell>{new Date(log.createdAt).toLocaleString()}</TableCell>
                                  <TableCell>{log.url}</TableCell>
                                  <TableCell>{log.counter}</TableCell>
                                  <TableCell>{new Date(log.updatedAt).toLocaleString()}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      count={filteredLogs.length}
                      rowsPerPage={pageSize}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AnalyticsLog;
