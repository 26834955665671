import axios from "axios";

const getBaseURL = () => {
  const protocol = window.location.protocol; // 'http:' or 'https:'
  let port;
  let host;

  if (window.location.hostname !== "localhost") {
    host = "auth.codeachi.com";
    port = "";
  } else {
    host = window.location.hostname;
    port = "5001";
  }
  // 'localhost' or your IP/domain

  return `${protocol}//${host}:${port}`;
};

const baseURL = getBaseURL();

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default axiosInstance;
