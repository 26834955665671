import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const [isSidebarShrunk, setIsSidebarShrunk] = useState(false);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const [isActivationMenuOpen, setIsActivationMenuOpen] = useState(false);
  const [isOrderMenuOpen, setIsOrderMenuOpen] = useState(false);
  const [isSupportsMenuOpen, setIsSupportsMenuOpen] = useState(false);
  const [isUsersMenuOpen, setIsUsersMenuOpen] = useState(false);
  const [isSystemTradersMenuOpen, setIsSystemTradersMenuOpen] = useState(false); // New state for SystemTraders menu

  useEffect(() => {
    setIsProductMenuOpen(location.pathname.startsWith("/auth/products"));
    setIsActivationMenuOpen(
      location.pathname.startsWith("/auth/generate-keys") ||
        location.pathname.startsWith("/auth/keys-details") ||
        location.pathname.startsWith("/auth/keys-activation")
    );
    setIsOrderMenuOpen(
      location.pathname.startsWith("/auth/order") ||
        location.pathname.startsWith("/auth/pending-carts") ||
        location.pathname.startsWith("/auth/coupons")
    );
    setIsSupportsMenuOpen(
      location.pathname.startsWith("/auth/contact") ||
        location.pathname.startsWith("/auth/contact-request")
    );
    setIsUsersMenuOpen(location.pathname.startsWith("/auth/user"));
    setIsSystemTradersMenuOpen( // Setting SystemTraders menu based on location
      location.pathname.startsWith("/auth/systemtraders/generate-key") ||
      location.pathname.startsWith("/auth/systemtraders/key-details")
    );
  }, [location.pathname]);

  const toggleMenu = (menuSetter) => (e) => {
    e.preventDefault();
    menuSetter((prev) => !prev);
  };

  const toggleSidebar = () => {
    setIsSidebarShrunk((prev) => !prev);
  };

  return (
    <div className={`quixnav ${isSidebarShrunk ? "sidebar-shrunk" : "sidebar-expanded"}`}>
      <div className="quixnav-scroll">
        <ul className="metismenu" id="menu">
          <li
            className="nav-label first"
            style={{
              color: "azure",
              fontWeight: 900,
              fontSize: 15,
              backgroundColor: "rgb(2, 40, 35)",
              marginLeft: 0,
              paddingLeft: "28px",
              width: isSidebarShrunk ? "80px" : "251px",
            }}
          >
            Navigation Menu
          </li>

          <li className={location.pathname === "/auth/dashboard" ? "active" : ""}>
            <Link to="/auth/dashboard">
              <i className="icon icon-app-store" />
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>

          <li className={isProductMenuOpen ? "active" : ""}>
            <a
              href="#"
              onClick={toggleMenu(setIsProductMenuOpen)}
              aria-expanded={isProductMenuOpen}
              className="nav-link"
            >
              <i className="fa fa-tags" aria-hidden="true"></i>
              <span className="nav-text caretWidth">Products</span>
              <i
                className={`fa-solid fa-chevron-${isProductMenuOpen ? "down" : "right"} rotate-chevron ${isProductMenuOpen ? "flip-up" : ""}`}
              />
            </a>
            <ul className={`metismenu ${isProductMenuOpen ? "mm-show" : "mm-collapse"}`}>
              <li className={location.pathname === "/auth/products" ? "active-submenu" : ""}>
                <Link to="/auth/products">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  <span className="nav-text shownav">Details</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/products/registration" ? "active-submenu" : ""}>
                <Link to="/auth/products/registration">
                  <i className="fa fa-line-chart" aria-hidden="true"></i>
                  <span className="nav-text shownav">Registrations</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/products/version" ? "active-submenu" : ""}>
                <Link to="/auth/products/version">
                  <i className="fa-solid fa-recycle"></i>
                  <span className="nav-text shownav">Version</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className={isActivationMenuOpen ? "active" : ""}>
            <a
              href="#"
              onClick={toggleMenu(setIsActivationMenuOpen)}
              aria-expanded={isActivationMenuOpen}
              className="nav-link"
            >
              <i className="fa fa-id-card-o" aria-hidden="true"></i>
              <span className="nav-text caretWidth">Activations</span>
              <i
                className={`fa-solid fa-chevron-${isActivationMenuOpen ? "down" : "right"} rotate-chevron ${isActivationMenuOpen ? "flip-up" : ""}`}
                style={{ paddingLeft: "-1px" }}
              />
            </a>
            <ul className={`metismenu ${isActivationMenuOpen ? "mm-show" : "mm-collapse"}`}>
              <li className={location.pathname === "/auth/generate-keys" ? "active-submenu" : ""}>
                <Link to="/auth/generate-keys">
                  <i className="fa fa-plus-square" aria-hidden="true"></i>
                  <span className="nav-text shownav">Generate Keys</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/keys-details" ? "active-submenu" : ""}>
                <Link to="/auth/keys-details">
                  <i className="fa fa-key" aria-hidden="true"></i>
                  <span className="nav-text shownav">Key Details</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/keys-activation" ? "active-submenu" : ""}>
                <Link to="/auth/keys-activation">
                  <i className="fa fa-unlock" aria-hidden="true"></i>
                  <span className="nav-text shownav">Activation Details</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className={isOrderMenuOpen ? "active" : ""}>
            <a
              href="#"
              onClick={toggleMenu(setIsOrderMenuOpen)}
              aria-expanded={isOrderMenuOpen}
            >
              <i className="fa fa-shopping-bag" aria-hidden="true"></i>
              <span className="nav-text caretWidth">Orders</span>
              <i
                className={`fa-solid fa-chevron-${isOrderMenuOpen ? "down" : "right"} rotate-chevron ${isOrderMenuOpen ? "flip-up" : ""}`}
                style={{ paddingLeft: "-1px" }}
              />
            </a>
            <ul className={`metismenu ${isOrderMenuOpen ? "mm-show" : "mm-collapse"}`}>
              <li className={location.pathname === "/auth/order" ? "active-submenu" : ""}>
                <Link to="/auth/order">
                  <i className="fa fa-shopping-basket" aria-hidden="true"></i>
                  <span className="nav-text shownav">Order details</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/pending-carts" ? "active-submenu" : ""}>
                <Link to="/auth/pending-carts">
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                  <span className="nav-text shownav">Pending Cart</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/coupons" ? "active-submenu" : ""}>
                <Link to="/auth/coupons">
                  <i className="fa fa-gift"></i>
                  <span className="nav-text shownav">Coupon</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className={isSupportsMenuOpen ? "active" : ""}>
            <a
              href="#"
              onClick={toggleMenu(setIsSupportsMenuOpen)}
              aria-expanded={isSupportsMenuOpen}
              className="nav-link"
            >
              <i className="fa fa-support" aria-hidden="true"></i>
              <span className="nav-text caretWidth">Supports</span>
              <i
                className={`fa-solid fa-chevron-${isSupportsMenuOpen ? "down" : "right"} rotate-chevron ${isSupportsMenuOpen ? "flip-up" : ""}`}
              />
            </a>
            <ul className={`metismenu ${isSupportsMenuOpen ? "mm-show" : "mm-collapse"}`}>
              <li className={location.pathname === "/auth/contact" ? "active-submenu" : ""}>
                <Link to="/auth/contact">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span className="nav-text shownav">Contact</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/contact-request" ? "active-submenu" : ""}>
                <Link to="/auth/contact-request">
                  <i className="fa fa-commenting-o" aria-hidden="true"></i>
                  <span className="nav-text shownav">Contact Request</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className={isUsersMenuOpen ? "active" : ""}>
            <a
              href="#"
              onClick={toggleMenu(setIsUsersMenuOpen)}
              aria-expanded={isUsersMenuOpen}
            >
              <i className="fa fa-user" aria-hidden="true"></i>
              <span className="nav-text caretWidth">Users</span>
              <i
                className={`fa-solid fa-chevron-${isUsersMenuOpen ? "down" : "right"} rotate-chevron ${isUsersMenuOpen ? "flip-up" : ""}`}
                style={{ paddingLeft: "11px" }}
              />
            </a>
            <ul className={`metismenu ${isUsersMenuOpen ? "mm-show" : "mm-collapse"}`}>
              <li className={location.pathname === "/auth/user/add" ? "active-submenu" : ""}>
                <Link to="/auth/user/add">
                  <i className="fas fa-user-plus" aria-hidden="true"/>
                  <span className="nav-text shownav">Add User</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/user/list" ? "active-submenu" : ""}>
                <Link to="/auth/user/list">
                  <i className="fa-solid fa-users-rays"  aria-hidden="true"></i>
                  <span className="nav-text shownav">User List</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/user/reports" ? "active-submenu" : ""}>
                <Link to="/auth/user/reports">
                  <i className="fas fa-chalkboard-teacher" aria-hidden="true" />
                  <span className="nav-text shownav">User Reports</span>
                </Link>
              </li>
            </ul>
          </li>

          {/* New SystemTraders Menu */}
          <li className={isSystemTradersMenuOpen ? "active" : ""}>
            <a
              href="#"
              onClick={toggleMenu(setIsSystemTradersMenuOpen)}
              aria-expanded={isSystemTradersMenuOpen}
              className="nav-link"
            >
              <i className="fa fa-cogs" aria-hidden="true"></i>
              <span className="nav-text caretWidth">SystemTraders</span>
              <i
                className={`fa-solid fa-chevron-${isSystemTradersMenuOpen ? "down" : "right"} rotate-chevron ${isSystemTradersMenuOpen ? "flip-up" : ""}`}
              />
            </a>
            <ul className={`metismenu ${isSystemTradersMenuOpen ? "mm-show" : "mm-collapse"}`}>
              <li className={location.pathname === "/auth/systemtraders/generate-key" ? "active-submenu" : ""}>
                <Link to="/auth/systemtraders/generate-key">
                  <i className="fa fa-key" aria-hidden="true"></i>
                  <span className="nav-text shownav">Generate Key</span>
                </Link>
              </li>
              <li className={location.pathname === "/auth/systemtraders/key-details" ? "active-submenu" : ""}>
                <Link to="/auth/systemtraders/key-details">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  <span className="nav-text shownav">Key Details</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className={location.pathname === "/auth/settings" ? "active" : ""}>
            <Link to="/auth/settings">
              <i className="fa fa-cog" aria-hidden="true"></i>
              <span className="nav-text">Settings</span>
            </Link>
          </li>

          <li className={location.pathname === "/auth/pricing-calculator" ? "active" : ""}>
            <Link to="/auth/pricing-calculator">
              <i className="fa fa-calculator" aria-hidden="true"></i>
              <span className="nav-text ">Pricing Calculator</span>
            </Link>
          </li>

          <li className={location.pathname === "/auth/analytics" ? "active" : ""}>
            <Link to="/auth/analytics">
              <i className="fa-solid fa-ban" aria-hidden="true"></i>
              <span className="nav-text ">Page Not Found Log</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
