import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice'; // We will create this slice next

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});


