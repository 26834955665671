import React, { useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";

const OrderPendingCart = () => {
  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        
        <title>Pending Cart | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        {/* row */}
        <div className="container-fluid">
          <div className="row page-titles mx-0"></div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderPendingCart;
