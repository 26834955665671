import React, { useEffect } from "react";
import { BrowserRouter as Router} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AppRoutes from "./AppRoutes"; // Import the new AppRoutes component

function App() {
  
  return (
    <Provider store={store}>
      <Router>
        <AppRoutes /> {/* Use the new AppRoutes component */}
      </Router>
    </Provider>
  );
}

export default App;
