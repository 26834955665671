import React, { useEffect, useState } from "react";
import axios from "../../axiosConfig";

const UsageDetails = ({ machineId }) => {
  const [features, setFeatures] = useState([]);
  const [usage, setUsage] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [register, setRegister] = useState({});

  const getUsageAndFeatures = async () => {
    try {
      const response = await axios.post("/api/product/registration", {
        machineId,
      });
      setRegister(response.data);
      setFeatures(response.data.product.features);
      setUsage(response.data.usage);
    } catch (error) {
      setResponseMessage("Error fetching usage and features: " + error.message);
      setResponseType("error");
    }
  };

  useEffect(() => {
    getUsageAndFeatures();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post("/api/product/registration/features", {
        machineId,
        features,
      });
      setResponseMessage("Features updated.");
      setResponseType("success");
    } catch (error) {
      setResponseMessage("Error updating features: " + error.message);
      setResponseType("error");
    }
  };

  const handleFeatureChange = (e, featureKey) => {
    const updatedFeatures = features.map((feature) =>
      feature.key === featureKey
        ? { ...feature, value: e.target.value }
        : feature
    );
    setFeatures(updatedFeatures);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title">Usage Details</h2>
      </div>
      <div className="card-body text-dark">
        <ul className="list-group list-group-flush">
          <li className="list-group-item"><strong>Machine ID:</strong> {machineId}</li>

          <li className="list-group-item ">
            <div className="row">
              <div className="col-md-4">
                <span className="badge badge-primary">Feature name </span>
              </div>
              <div className="col-md-4">
                <span className="badge badge-primary">Used </span>
              </div>
              <div className="col-md-4">
                <span className="badge badge-primary">Limit </span>
              </div>
            </div>
          </li>
          {features.map((feature) => (
            <li key={feature.key} className="list-group-item">
              <div className="row">
                <div className="col-md-4">{feature.key}:</div>
                <div className="col-md-4">
                  {usage.find((item) => item.key === feature.key)?.value ||
                    "N/A"}{" "}
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    className="form-control"
                    value={feature.value}
                    onChange={(e) => handleFeatureChange(e, feature.key)}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="row">
          <div className="col-md-4">
            <button className="btn btn-primary mt-3" onClick={handleSubmit}>
              Submit
            </button>
          </div>
          <div className="col-md-8">
            {responseMessage && (
              <p
                className={`mt-3 text-${
                  responseType === "success" ? "success" : "danger"
                }`}
              >
                {responseMessage}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageDetails;
