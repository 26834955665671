import React, { useState, useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";

import axios from "../axiosConfig";

const GenerateKeys = () => {
  const [products, setProducts] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [issuedToEmail, setIssuedToEmail] = useState("");
  const [product, setProduct] = useState("");
  const [validTill, setValidTill] = useState("");
  const [validTillDate, setValidTillDate] = useState("");
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState("");
  const [features, setFeatures] = useState([]);
  const [generatedKeys, setGeneratedKeys] = useState([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const [totalKeys, setTotalKeys] = useState(1);
  const [machineLimit, setMachineLimit] = useState(1);

  useEffect(() => {
 
    const fetchLicenseOptions = async () => {
      try {
        const response = await axios.get("/api/product/all", {
          withCredentials: true,
        });

        setProducts(response.data);
      } catch (error) {
        setProducts([]);
      }
    };

    fetchLicenseOptions();
  }, []);

  const handleGenerateKey = async () => {
   
    try {
     
      const response = await axios.post("/api/activation/generate-key", {
        orderId,
        issuedEmail: issuedToEmail,
        product,
        validTill: new Date(validTillDate),
        license: selectedLicense,
        features: JSON.stringify(features),
        totalKeys,
        machineLimit,
      });
      console.log("response.data", response.data);
      setGeneratedKeys(response.data);
    } catch (error) {
      console.error("Error generating key:", error);
    }
  };

  function getProductLicenses(productName) {
    const rProduct = products.find((prod) => prod.name === productName);
    return rProduct ? rProduct.license : null;
  }

  useEffect(() => {
    if (product) {
      const license = getProductLicenses(product);
      setLicenseOptions(license);
    }
  }, [product]);

  function getSelectedLicenseFeatures(selectedLicenseName) {
    const selectedLicense = licenseOptions.find(
      (license) => license.name === selectedLicenseName
    );
    return selectedLicense ? selectedLicense.features : [];
  }

  useEffect(() => {
    if (selectedLicense) {
      const features = getSelectedLicenseFeatures(selectedLicense);

      setFeatures(features);
    }
  }, [selectedLicense]);

  useEffect(() => {
    if (validTill === "oneYear") {
      const today = new Date();
      const oneYearLater = new Date(
        today.getTime() + 365 * 24 * 60 * 60 * 1000
      );
      const formattedDate = oneYearLater.toISOString().split("T")[0];
      setValidTillDate(formattedDate);
    }
  }, [validTill]);

  const handleFutureDateChange = (e) => {
    setValidTillDate(e.target.value);
  };

  const handleCopyKeys = () => {
   
    navigator.clipboard
      .writeText(generatedKeys.join("\n"))
      .then(() => setCopySuccess(true))
      .catch((error) => console.error("Error copying keys:", error));
  };

  return (
    <Layout>
      <Helmet>
        <meta charset="UTF-8" />
        
        <title>Generate Keys | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body">
        {/* row */}
        <div className="container-fluid">
        <div className="row page-titles mx-0">
            <div className="col-sm-6 p-md-0">
              <div className="welcome-text">
          
                <p className="mb-0">Generate Keys</p>
              </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Generate Keys</a>
                </li>
              </ol>
            </div>
          </div>
          <div className="row page-titles mx-0">
            <div className="container" >
              <div className="row">
                <div className="col-lg-12">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Order ID:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Issued to Email:</label>
                          <input
                            type="email"
                            className="form-control"
                            value={issuedToEmail}
                            onChange={(e) => setIssuedToEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>


                          {/* <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Order ID:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Issued to Email:</label>
                          <input
                            type="email"
                            className="form-control"
                            value={issuedToEmail}
                            onChange={(e) => setIssuedToEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="form-group" >
                      <label>Valid Till: {validTillDate}</label>

                      <div className="form-check">
                        <input
                          type="radio"
                          id="oneYear"
                          className="form-check-input"
                          checked={validTill === "oneYear"}
                          onChange={() => setValidTill("oneYear")}
                        />
                        <label htmlFor="oneYear" className="form-check-label">
                          One year from today
                        </label>
                      </div>
                    </div>

                    <div className="col-md-12 p-0 m-0">
                      <div className="row p-0 m-0">
                        <div className="form-check">
                          <input
                            type="radio"
                            id="futureDate"
                            className="form-check-input"
                            checked={validTill === "futureDate"}
                            onChange={() => setValidTill("futureDate")}
                          />
                          <label
                            htmlFor="futureDate"
                            className="form-check-label col-md-12 p-0 mr-4 "
                          >
                           <strong>Select a future date</strong> 
                          </label>
                        </div>
                        <input
                          type="date"
                          className="form-control  col-md-5"
                          value={validTillDate}
                          onChange={handleFutureDateChange}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="">Number of keys:</label>
                          <input
                            type="number"
                            className="form-control"
                            value={totalKeys}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);

                              if ((value > 0 && value < 101) || isNaN(value))
                                setTotalKeys(value);
                            }}
                            onBlur={(e) => {
                              let value = parseInt(e.target.value);
                              if (isNaN(value) || value < 1) {
                                value = 1;
                                setTotalKeys(value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="">Machine Limit:</label>
                          <input
                            type="number"
                            className="form-control"
                            value={machineLimit}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);

                              if ((value > 0 && value < 101) || isNaN(value))
                                setMachineLimit(value);
                            }}
                            onBlur={(e) => {
                              let value = parseInt(e.target.value);
                              if (isNaN(value) || value < 1) {
                                value = 1;
                                setMachineLimit(value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6" >
                        <div className="form-group">
                          <label>Product:</label>
                          <select
                            className="form-control"
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                          >
                            <option value="adls">Select Product</option>
                            
                            {products &&
                              products.map((product) => (
                                <option key={product.name} value={product.name}>
                                  {product.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>License:</label>
                          <select
                            className="form-control"
                            value={selectedLicense}
                            onChange={(e) => setSelectedLicense(e.target.value)}
                          >
                            <option value="None">Select License</option>
                            {/* Populate options dynamically */}
                            {licenseOptions &&
                              licenseOptions.map((license) => (
                                <option key={license.name} value={license.name}>
                                  {license.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div
                      className=""
                      style={{ maxHeight: "250px", overflowY: "auto" }}
                    >
                      {features &&
                        features.map((feature, index) => (
                          <div key={index} className="form-group row m-1 p-1">
                            <label className="col-md-4">{feature.key}</label>
                            <input
                              type={
                                typeof feature.value === "boolean"
                                  ? "checkbox"
                                  : "text"
                              }
                              className="form-control col-md-6"
                              value={feature.value}
                              onChange={(e) => {
                                const updatedFeatures = [...features];
                                updatedFeatures[index].value =
                                  e.target.type === "checkbox"
                                    ? e.target.checked
                                    : e.target.value;
                                setFeatures(updatedFeatures);
                              }}
                            />
                          </div>
                        ))}
                    </div>

                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleGenerateKey}
                     
                      >
                        Generate Key
                      </button>
                    </div>
                  </form>
                </div>

                <div className="col-lg-6">
                  {/* Display generated keys */}
                  <div className="form-group">
                    <label>Generated Keys:</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      value={generatedKeys.join("\n")}
                      readOnly
                    ></textarea>
                  </div>
                  {/* Copy button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleCopyKeys}
                  >
                    {copySuccess ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GenerateKeys;
