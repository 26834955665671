import React, { useState, useEffect } from "react";
import axios from "../../axiosConfig";

const AccessSettings = () => {
  const [accessList, setAccessList] = useState([]);
  const [newAccessItem, setNewAccessItem] = useState("");
  const [selectedAccessItems, setSelectedAccessItems] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const getAccessList = async () => {
    try {
      const response = await axios.get("api/settings/access-list");
      if (Array.isArray(response.data.data)) {
        setAccessList(response.data.data);
      } else {
        setAccessList([]);
      }
    } catch (error) {
      console.error("Error fetching access list:", error);
    }
  };

  useEffect(() => {
    getAccessList();
  }, []);

  const handleCheckboxChange = (accessItem) => {
    const selectedIndex = selectedAccessItems.indexOf(accessItem);
    if (selectedIndex === -1) {
      setSelectedAccessItems([...selectedAccessItems, accessItem]);
    } else {
      const updatedSelectedItems = [...selectedAccessItems];
      updatedSelectedItems.splice(selectedIndex, 1);
      setSelectedAccessItems(updatedSelectedItems);
    }
  };

  const handleDeleteSelected = () => {
    const updatedAccessList = accessList.filter(
      (item) => !selectedAccessItems.includes(item)
    );
    setAccessList(updatedAccessList);
    setSelectedAccessItems([]);
  };

  const handleAddNewItem = () => {
    if (newAccessItem.trim() !== "") {
      setAccessList([...accessList, newAccessItem]);
      setNewAccessItem("");
    }
  };

  const handleSaveSettings = async () => {
    try {
      const response = await axios.post("api/settings/access-list", {
        data: accessList,
      });
      setSuccessMessage(response.data.message);
    } catch (error) {
      setErrorMessage("Error saving settings. Please try again.");
      console.error("Error saving settings:", error);
    }
  };

  const closeMessage = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Access Settings</h4>
      </div>
      <div className="card-body" >
        <ul className="list-group" style={{ maxHeight: "250px", overflowY: "auto" }}>
          {accessList.map((accessItem, index) => (
            <li key={index} className="list-group-item">
              <input
                type="checkbox"
                checked={selectedAccessItems.includes(accessItem)}
                onChange={() => handleCheckboxChange(accessItem)}
              />
              <label style={{ marginLeft: "10px" }}>{accessItem}</label>
            </li>
          ))}
        </ul>
      </div>
      <div className="card-footer">
        <div className="form-inline mb-3">
          <input
            type="text"
            className="form-control mr-2"
            placeholder="New Access Item"
            value={newAccessItem}
            onChange={(e) => setNewAccessItem(e.target.value)}
          />
          <button className="btn btn-secondary" style={{borderRadius:"0"}} onClick={handleAddNewItem}>
            Add
          </button>
        </div>
        <div>
          <button className="btn btn-danger mr-2" onClick={handleDeleteSelected}>
            Delete Selected
          </button>
          <button className="btn btn-primary" onClick={handleSaveSettings}>
            Save Settings
          </button>
        </div>
        {successMessage && (
          <div className="alert alert-success mt-3" role="alert">
            {successMessage}
            <button type="button" className="close" onClick={closeMessage}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-danger mt-3" role="alert">
            {errorMessage}
            <button type="button" className="close" onClick={closeMessage}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessSettings;
