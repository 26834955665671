import React, { useEffect, useState } from "react";
import Layout from "../components/layouts/Layout";
import { Helmet } from "react-helmet";
import axios from "../axiosConfig";


const Profile = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    role: "",
    status: "",
    imagePath: "http://localhost:5001/static/avatar.png",
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resError, setResError] = useState("");
  const [resSuccess, setResSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = (
          await axios.get("/api/user/profile", {
            headers: {
              "Content-Type": "application/json",
            },
          })
        ).data;
        setData(response);
      } catch (error) {
        console.error("Error getting cart:", error);
      }
    };

    getData();
  }, []);

  const clearMessage = () => {
    setResError("");
    setResSuccess("");
  };

  const HandleChangePassword = async () => {
    setResError("");
    if (newPassword !== confirmPassword) {
      setResError("Your new password and confirm password are not matching");
      return;
    } else if (!newPassword) {
      setResError("New Password cannot be blank");
      return;
    } else if (currentPassword === newPassword) {
      setResError("New Password cannot be same as current password");
      return;
    }

    try {
      const response = (
        await axios.post(
          "/api/user/change-password",
          { currentPassword, newPassword },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
      ).data;

      setResSuccess("Your password changed successfully");
    } catch (error) {
      console.error("Error:", error);
      setResError(error.response.data.message);
    }
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Profile | CodeAchi Technologies</title>
      </Helmet>

      <div className="content-body min-body-height">
        <div className="container">
          <div className="row">
            <div className="card-info-wrap ml-5 col-md-3 profile-card">
              <div className="profile-title">Profile</div>
              <div className="wrap">
                <div>
                  {data.imagePath && (
                    <div className="text-center">
                      <img
                        crossOrigin="anonymous"
                        src={data.imagePath}
                        className="img-fluid rounded-circle"
                        alt="Profile Placeholder"
                      />
                    </div>
                  )}

                  <h2>{data.name || ""}</h2>
                  <p>
                    <strong>Email:</strong> {data.email || ""}
                  </p>
                  <p>
                    <strong>Phone:</strong> {data.phone || ""}
                  </p>
                  <p>
                    <strong>Role:</strong> {data.role || ""}
                  </p>
                </div>
              </div>
            </div>

            <div className="card-info-wrap ml-5 col-md-6 profile-card">
              <div className="profile-title">Change Password</div>
              <div className="wrap">
                <div>
                  <div className="form-group">
                    <label>
                      <strong>Current Password</strong>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={currentPassword}
                      onFocus={clearMessage}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      <strong>New Password</strong>
                    </label>
                    <div className="input-with-icon">
                      <input
                        onFocus={clearMessage}
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i
                            className="fa fa-eye-slash text-muted"
                            aria-hidden="true"
                          />
                        ) : (
                          <i
                            className="fa fa-eye text-dark"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      <strong>Confirm Password</strong>
                    </label>
                    <div className="input-with-icon">
                      <input
                        onFocus={clearMessage}
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i
                            className="fa fa-eye-slash text-muted"
                            aria-hidden="true"
                          />
                        ) : (
                          <i
                            className="fa fa-eye text-dark"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="text-center">
                    {resError && (
                      <div className="danger text-danger mb-2">
                        Error: {resError}
                      </div>
                    )}
                    {resSuccess && (
                      <div className="success text-success mb-2">
                        {resSuccess}
                      </div>
                    )}
                    <button
                      type="button"
                      onClick={HandleChangePassword}
                      className="btn btn-primary btn-block dif-color"
                    >
                      Submit change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
